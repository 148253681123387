// const PARSED_DATA_EXAMPLE = [{
// }];

import isNil from 'ramda/src/isNil';

export const parse = rawData => {
    const { data } = rawData;

    return data.map(item => ({
        timestamp: item.t * 1000,
        value: isNil(item.v) ? null : parseInt(item.v, 10),
    }));
};
