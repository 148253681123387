import update from 'immutability-helper';
import trim from 'ramda/src/trim';

import ActionTypes from 'constants/ActionTypes';
import { HTTPS } from 'constants/Protocols';

const initialState = {
    url: '',
    urlProtocol: HTTPS,
};

const comparingBoxReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_COMPARING_BOX_URL_SET: {
            const url = trim(action.payload);
            const withoutProtocolUrl = url.replace(/^https?:\/\//g, '');

            return update(state, {
                url: { $set: withoutProtocolUrl },
            });
        }
        case ActionTypes.UI_COMPARING_BOX_URL_PROTOCOL_SET: {
            return update(state, {
                urlProtocol: { $set: action.payload },
            });
        }
        default: {
            return state;
        }
    }
};

export default comparingBoxReducer;
