import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    app: {
        visibility: false,
    },
    list: {
        currentId: null,
        filter: '',
        visibility: false,
    },
    history: {
        visibility: false,
    },
};

const panelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_PANELS_LIST_FILTER_SET: {
            return update(state, {
                list: {
                    filter: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_LIST_FILTER_RESET: {
            return update(state, {
                list: {
                    filter: {
                        $set: initialState.list.filter,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_HISTORY_SHOW: {
            return update(state, {
                history: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_HISTORY_CLOSE: {
            return update(state, {
                history: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_LIST_SHOW: {
            return update(state, {
                list: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_LIST_CLOSE: {
            return update(state, {
                list: {
                    visibility: {
                        $set: false,
                    },
                    currentId: {
                        $set: null,
                    },
                    filter: {
                        $set: '',
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_RECEIVED: {
            return update(state, {
                list: {
                    currentId: {
                        $apply: id => (id === action.payload ? null : id),
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_EXPORT_REQUESTED: {
            return update(state, {
                list: {
                    visibility: {
                        $set: false,
                    },
                    currentId: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_APP_SHOW: {
            return update(state, {
                app: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_APP_CLOSE: {
            return update(state, {
                app: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default panelsReducer;
