import reverse from 'ramda/src/reverse';

import { parseIntWithNVFallback, parseStringWithNAFallback } from 'sources/parsers/commons';

export const parse = rawData =>
    reverse(
        rawData.map(item => {
            const type = parseInt(item.t, 10);

            return {
                id: item._id, // eslint-disable-line no-underscore-dangle
                url: parseStringWithNAFallback(item.url),
                type,
                lps: parseIntWithNVFallback(item.lps),
            };
        }),
    );
