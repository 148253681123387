import Environments from 'mangools-commons/lib/constants/Environments';
import { getMangoolsUrls } from 'mangools-commons/lib/constants/getMangoolsUrls';

export const API_VERSION = 'v3';

const CONFIG = {
    API_HOST: process.env.API_HOST,
    API_PATH: `/${API_VERSION}/kwfinder`,
    SERPWATCHER_API_PATH: `/${API_VERSION}/serpwatcher`,
    APP_ENV: Environments.PRODUCTION,
    APP_HOST: `${process.env.APPS_ROOT_HOST}/kwfinder`,
    APP_VERSION: process.env.APP_VERSION, // eslint-disable-line no-undef
    LOCAL_STORAGE_VERSION: 3, // NOTE: Increase when LS reset is needed
    MANGOOLS_API_HOST: process.env.MANGOOLS_APP_HOST,
    ROLLBAR_API_KEY: '0102f21f8fb748b5a0818dee8f5ee60a',
    MANGOOLS_URLS: getMangoolsUrls(Environments.PRODUCTION),
    development() {
        return CONFIG.APP_ENV === Environments.DEVELOPMENT;
    },
    production() {
        return CONFIG.APP_ENV === Environments.PRODUCTION;
    },
    staging() {
        return CONFIG.APP_ENV === Environments.STAGING;
    },
};

export default CONFIG;
