import { append, reduce, pipe, sort, mergeRight } from 'ramda';

import { parseIntWithNVFallback, parseLocationLabel } from 'sources/parsers/commons';

/* eslint-disable no-underscore-dangle */
const parseLocation = rawLocation => ({
    id: rawLocation._id,
    code: rawLocation.code,
    label: parseLocationLabel(rawLocation.label),
});

const parseTracking = rawTracking => {
    return {
        id: rawTracking._id,
        domain: rawTracking.domain,
        platformId: parseIntWithNVFallback(rawTracking.platform_id),
        location: parseLocation(rawTracking.location),
    };
};

export const parse = pipe(
    reduce(
        ({ allIds, byId }, rawTracking) => {
            const tracking = parseTracking(rawTracking);
            const trackingId = `${tracking.domain}_${tracking.location.id}_${tracking.platformId}`;

            return {
                allIds: append(trackingId, allIds),
                byId: mergeRight({ [trackingId]: tracking }, byId),
            };
        },
        { allIds: [], byId: {} },
    ),
    ({ allIds, byId }) => {
        return {
            allIds: sort((idA, idB) => idA.localeCompare(idB), allIds),
            byId,
        };
    },
);
/* eslint-enable no-underscore-dangle */
