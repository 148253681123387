import config from 'appConfig';
import isNil from 'ramda/src/isNil';
import { parse, parseMeta } from 'sources/parsers/ResultDataParser';

import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import HumanService from 'mangools-commons/lib/services/HumanService';

// Constants
const ENDPOINT = '/keyword-imports';

/**
 * Data source class handling communication with API for import kw data.
 */
class ImportSource {
    static getData(accessToken, { keywords, languageId, locationId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}`;
        const body = { keywords, location_id: locationId };

        if (!isNil(languageId)) {
            body.language_id = languageId;
        }

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: {
                                data: parse(data),
                                meta: parseMeta(data),
                            },
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default ImportSource;
