import React from 'react';
import { node } from 'prop-types';

function TrackingsWrapper(props) {
    return <div className="kw-addtolist-container uk-flex uk-flex-column">{props.children}</div>;
}

TrackingsWrapper.propTypes = {
    children: node.isRequired,
};

export { TrackingsWrapper };
