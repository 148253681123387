// const PARSED_DATA_EXAMPLE = [{
//     code: 'sk',
//     id: 2703,
//     label: 'Country',
//     type: 'Country'
// }, {
//     code: 'us',
//     id: 1025290,
//     label: 'Slovan',
//     type: 'City'
// }];

import { parseLocationLabel } from 'sources/parsers/commons';

export const parseDetail = rawData => ({
    code: rawData.code,
    id: parseInt(rawData._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawData.label),
    type: rawData.target_type,
});

export const parse = rawData => rawData.map(item => parseDetail(item));
