import React from 'react';
import { bool, func } from 'prop-types';
import classnames from 'classnames';
import RankTranslatorService from 'mangools-commons/lib/services/RankTranslatorService';
import RankColorizerService from 'mangools-commons/lib/services/RankColorizerService';
import MessageHolder from 'components/messages/MessageHolder';

/* eslint-disable max-len */
function GaugeInfoMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header">
                    <h3 className="font-30 uk-text-bold color-black">Keyword SEO Difficulty</h3>
                </div>
                <div className="mg-modal-content">
                    <p className="mg-alert is-info font-14">
                        <strong>Keyword SEO Difficulty</strong> developed by Mangools.
                        <br />
                        Calculated from the <strong>Link Profile Strength (LPS)</strong>
                        <br />
                        of URLs ranking <strong>in the 1st SERP</strong>.
                    </p>

                    <div className="mg-results uk-padding-remove mg-margin-b-15">
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(0))}>
                                    <strong>0</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(14))}>
                                    <strong>14</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(0)}</div>
                        </div>
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(15))}>
                                    <strong>15</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(29))}>
                                    <strong>29</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(15)}</div>
                        </div>
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(30))}>
                                    <strong>30</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(49))}>
                                    <strong>49</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(30)}</div>
                        </div>
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(50))}>
                                    <strong>50</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(69))}>
                                    <strong>69</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(50)}</div>
                        </div>
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(70))}>
                                    <strong>70</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(84))}>
                                    <strong>84</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(70)}</div>
                        </div>
                        <div className="mg-results-tr uk-flex">
                            <div className="mg-results-td uk-width-2-3">
                                <span className={classnames('mg-rank', RankColorizerService.getColor(85))}>
                                    <strong>85</strong>
                                </span>
                                <span className="mg-padding-0-15">-</span>
                                <span className={classnames('mg-rank', RankColorizerService.getColor(100))}>
                                    <strong>100</strong>
                                </span>
                            </div>
                            <div className="mg-results-td uk-width-1-3">{RankTranslatorService.translate(85)}</div>
                        </div>
                    </div>
                    <a
                        className="mg-btn is-blue is-small mg-margin-t-10"
                        href="https://mangools.com/blog/2016/10/20/what-is-keyword-seo-difficulty/"
                        rel="noopener"
                        target="_blank"
                    >
                        Read more about Keyword Difficulty
                    </a>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}
/* eslint-enable max-len */

GaugeInfoMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default GaugeInfoMessage;
