import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import announcementsReducer from 'reducers/data/announcementsReducer';
import dashboardReducer from 'reducers/ui/dashboardReducer';
import defaultsReducer from 'reducers/ui/defaultsReducer';
import dropdownsReducer from 'reducers/ui/dropdownsReducer';
import filterReducer from 'reducers/ui/filterReducer';
import historyReducer from 'reducers/data/historyReducer';
import kdHistoryReducer from 'reducers/data/kdHistoryReducer';
import listsReducer from 'reducers/data/listsReducer';
import locationsReducer from 'reducers/data/locationsReducer';
import messagesReducer from 'reducers/ui/messagesReducer';
import miscReducer from 'reducers/ui/miscReducer';
import panelsReducer from 'reducers/ui/panelsReducer';
import paramsReducer from 'reducers/paramsReducer';
import resultsReducer from 'reducers/data/resultsReducer';
import serpResultsReducer from 'reducers/data/serpResultsReducer';
import trendsReducer from 'reducers/data/trendsReducer';
import userReducer from 'reducers/userReducer';
import currenciesReducer from 'reducers/data/currenciesReducer';
import serpwatcherTrackingsReducer from 'reducers/data/serpwatcherTrackingsReducer';
import competitorsReducer from 'reducers/data/competitorsReducer';
import urlDataReducer from 'reducers/urlDataReducer';
import comparingBoxReducer from 'reducers/ui/comparingBoxReducer';
import relativeKdReducer from 'reducers/ui/relativeKdReducer';

const rootReducer = combineReducers({
    data: combineReducers({
        announcements: announcementsReducer,
        history: historyReducer,
        kdHistory: kdHistoryReducer,
        lists: listsReducer,
        locations: locationsReducer,
        results: resultsReducer,
        serpResults: serpResultsReducer,
        trends: trendsReducer,
        currencies: currenciesReducer,
        serpwatcherTrackings: serpwatcherTrackingsReducer,
        competitors: competitorsReducer,
    }),
    params: paramsReducer,
    routing: routerReducer,
    ui: combineReducers({
        dashboard: dashboardReducer,
        defaults: defaultsReducer,
        dropdowns: dropdownsReducer,
        filter: filterReducer,
        messages: messagesReducer,
        misc: miscReducer,
        panels: panelsReducer,
        comparingBox: comparingBoxReducer,
        relativeKd: relativeKdReducer,
    }),
    user: userReducer,
    urlData: urlDataReducer,
});

export default rootReducer;
