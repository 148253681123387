import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoonAuto } from 'mangools-commons/lib/constants/Icons';

import {
    faArrowRight,
    faArrowUp,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCompress,
    faCopy,
    faDownload,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpand,
    faExternalLinkAlt,
    faFont,
    faGift,
    faGlobeAmericas,
    faHistory,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLevelUpAlt,
    faList,
    faLock,
    faUnlock,
    faMapMarkerAlt,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMousePointer,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faQuestion,
    faReply,
    faRocket,
    faSearch,
    faShareAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faStar,
    faSun,
    faSync,
    faTimes,
    faUser,
    faUserCog,
    faWaveSquare,
    faCoins,
    faSyncAlt,
    faLaptop,
    faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
    faClock,
    faClone,
    faCreditCard,
    faDotCircle,
    faFileExcel,
    faHandPaper,
    faLightbulb,
    faQuestionCircle,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(
    faArrowRight,
    faArrowUp,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faClone,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCompress,
    faCopy,
    faCreditCard,
    faDotCircle,
    faDownload,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExternalLinkAlt,
    faExpand,
    faFileExcel,
    faFont,
    faGift,
    faGlobeAmericas,
    faGoogle,
    faHandPaper,
    faHistory,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLevelUpAlt,
    faLightbulb,
    faList,
    faMapMarkerAlt,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMoonAuto,
    faMousePointer,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faQuestion,
    faQuestionCircle,
    faReply,
    faRocket,
    faSearch,
    faShareAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faStar,
    faSun,
    faSync,
    faTimes,
    faTrashAlt,
    faUserCog,
    faUser,
    faWaveSquare,
    faCoins,
    faSyncAlt,
    faLaptop,
    faLock,
    faUnlock,
    faExchangeAlt,
);
