import React from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/lib/constants/Urls';

import MessageHolder from 'components/messages/MessageHolder';
import UrlTypes from 'constants/UrlTypes';

/* eslint-disable max-len */
function UrlTypeInfoMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose} classNames="is-large">
                <div className="mg-modal-header">
                    <h3 className="font-30 uk-text-bold color-black">Supported Input Types</h3>
                </div>
                <div className="mg-modal-content">
                    <div className="uk-text-left">
                        <div className="uk-grid">
                            <div className="uk-width-medium-4-10 uk-text-right uk-text-left-small">
                                <strong>
                                    tumblr.com
                                    <br />
                                    mangools.com
                                </strong>
                            </div>
                            <div className="uk-visible-small mg-margin-b-15" />
                            <div className="uk-width-medium-2-10 uk-text-center uk-text-left-small">
                                <strong className="mg-label is-yellow font-12 mg-margin-b-15">
                                    {UrlTypes.ROOT_DOMAIN}
                                </strong>
                            </div>
                            <div className="uk-width-medium-4-10">
                                <p className="color-grey font-14">
                                    The main and recommended form of input. All ranking keywords for any of the domain
                                    URL will be returned.
                                </p>
                            </div>
                        </div>
                        <div className="uk-grid-divider" />
                        <div className="uk-grid">
                            <div className="uk-width-medium-4-10 uk-text-right uk-text-left-small">
                                <strong>
                                    myblog.tumblr.com
                                    <br />
                                    app.mangools.com/kwfinder
                                </strong>
                            </div>
                            <div className="uk-visible-small mg-margin-b-15" />
                            <div className="uk-width-medium-2-10 uk-text-center uk-text-left-small">
                                <strong className="mg-label is-yellow font-12 mg-margin-b-15">
                                    {UrlTypes.SUBDOMAIN}
                                </strong>
                            </div>
                            <div className="uk-width-medium-4-10">
                                <p className="color-grey font-14">
                                    Use this form to find keywords for any subdomain, like popular blog networks, etc.
                                </p>
                            </div>
                        </div>
                        <div className="uk-grid-divider" />
                        <div className="uk-grid">
                            <div className="uk-width-medium-4-10 uk-text-right uk-text-left-small">
                                <strong>
                                    medium.com/my-amazing-blog-post
                                    <br />
                                    kwfinder.com/features/
                                </strong>
                            </div>
                            <div className="uk-visible-small mg-margin-b-15" />
                            <div className="uk-width-medium-2-10 uk-text-center uk-text-left-small">
                                <strong className="mg-label is-yellow font-12 mg-margin-b-15">
                                    {UrlTypes.EXACT_URL}
                                </strong>
                            </div>
                            <div className="uk-visible-small mg-margin-b-15" />
                            <div className="uk-width-medium-4-10">
                                <p className="color-grey font-14">
                                    Use this form to find keywords for any URL, like popular blog posts, etc. Searches
                                    are case sensitive. Also, adding or subtracting a trailing slash changes search
                                    results.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid-divider" />
                    <a
                        className="mg-btn is-blue is-small uk-text-bold "
                        href={Urls.KWFINDER_GUIDE_URL}
                        rel="noppener"
                        target="_blank"
                    >
                        <FontAwesomeIcon icon="external-link-alt" />
                        Find out more
                    </a>
                    <div className="uk-visible-small mg-margin-b-30" />
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}
/* eslint-enable max-len */

UrlTypeInfoMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default UrlTypeInfoMessage;
