export const SELECT_ALL_KEYWORDS_TOGGLE = 'SELECT_ALL_KEYWORDS_TOGGLE';
export const COPY_KEYWORDS = 'COPY_KEYWORDS';
export const EXPORT_KEYWORDS = 'EXPORT_KEYWORDS';
export const ARROW_DOWN = 'ARROW_DOWN';
export const ARROW_UP = 'ARROW_UP';
export const SELECT_KEYWORD_TOGGLE = 'SELECT_KEYWORD_TOGGLE';
export const ENTER = 'ENTER';
export const SHOW_SHORTCUTS_TOGGLE = 'SHOW_SHORTCUTS_TOGGLE';
export const CLOSE_ALL = 'CLOSE_ALL';
export const SELECTED_KEYWORDS_DELETE = 'SELECTED_KEYWORDS_DELETE';
export const SELECTED_KEYWORDS_LIST_ADD = 'SELECTED_KEYWORDS_LIST_ADD';
export const SEARCH = 'SEARCH';
export const CURRENCY_MESSAGE_TOGGLE = 'CURRENCY_MESSAGE_TOGGLE';
export const IGNORE = 'IGNORE'; // https://www.notion.so/itrinitycom/Technical-intacracies-3e705c50b0c74541a0afcdd2a15cab8b#7ab90cdc128d46148f56fe2d5b431cfd

export default {
    [SELECT_ALL_KEYWORDS_TOGGLE]: ['command+a', 'ctrl+a'],
    [COPY_KEYWORDS]: ['command+c', ' ctrl+c'],
    [EXPORT_KEYWORDS]: 'e',
    [ARROW_DOWN]: ['shift+down', 'shift+j', 'down', 'j'],
    [ARROW_UP]: ['shift+up', 'shift+k', 'up', 'k'],
    [SELECT_KEYWORD_TOGGLE]: 'space',
    [ENTER]: 'enter',
    [CLOSE_ALL]: 'esc',
    [SHOW_SHORTCUTS_TOGGLE]: 'shift+?',
    [SELECTED_KEYWORDS_LIST_ADD]: 'l',
    [SELECTED_KEYWORDS_DELETE]: navigator.platform.includes('Mac') ? 'backspace' : 'del',
    [SEARCH]: ['command+f', 'ctrl+f', '/'],
    [CURRENCY_MESSAGE_TOGGLE]: 'y',
    [IGNORE]: ['command+l'], // workaround for 'l' without command
};
