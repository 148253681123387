import React from 'react';
import { bool, func, string } from 'prop-types';
import unavailableImg from 'images/sad.svg';
import MessageHolder from 'components/messages/MessageHolder';
import Strings from 'constants/Strings';
import FailureMessageRetryBtn from './FailureMessageRetryBtn';

function FailureMessage(props) {
    const renderDefaultMsg = () => {
        switch (props.details) {
            case Strings.messages.failure.import_error_424: {
                return null;
            }
            case Strings.messages.failure.fetch_results_error_kw_by_domain_422: {
                return null;
            }
            default:
                return <p>{Strings.messages.failure.footer_sorry}</p>;
        }
    };

    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <img src={unavailableImg} alt="Oh no" width="120" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">{props.header}</h3>
                    {props.details ? <p>{props.details}</p> : null}
                    {renderDefaultMsg()}
                    <FailureMessageRetryBtn failureMessage={props.details} />
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

FailureMessage.propTypes = {
    details: string,
    header: string,
    onClose: func.isRequired,
    visible: bool.isRequired,
};

FailureMessage.defaultProps = {
    details: null,
    header: 'Sorry, something went wrong',
};

export default FailureMessage;
