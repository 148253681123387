// const PARSED_DATA = {
//     relatedRowsLimit: 25,
//     relatedLimit: 2,
//     relatedRemaining: 2,
//     serpLimit: 2,
//     serpRemaining: 2,
//     trackedKeywordLimit: 200,
//     upgradedAccount: false
// }

import { parseIntWithNVFallback } from 'sources/parsers/commons';

export const parse = rawData => ({
    backlinkLimit: parseInt(rawData.resources.links.limit, 10),
    backlinkRemaining: parseInt(rawData.resources.links.remaining, 10),
    backlinkResetSeconds: parseIntWithNVFallback(rawData.resources.links.reset),
    competitorRowsLimit: parseInt(rawData['competitor-keywords-rows'], 10),
    relatedLimit: parseInt(rawData.resources['related-keywords'].limit, 10),
    relatedRemaining: parseInt(rawData.resources['related-keywords'].remaining, 10),
    relatedResetSeconds: parseIntWithNVFallback(rawData.resources['related-keywords'].reset),
    relatedRowsLimit: parseInt(rawData['related-keywords-rows'], 10),
    serpLimit: parseInt(rawData.resources.serps.limit, 10),
    serpRemaining: parseInt(rawData.resources.serps.remaining, 10),
    serpResetSeconds: parseIntWithNVFallback(rawData.resources.serps.reset),
    siteLimit: parseInt(rawData.resources['sp-overview'].limit, 10),
    siteRemaining: parseInt(rawData.resources['sp-overview'].remaining, 10),
    siteResetSeconds: parseIntWithNVFallback(rawData.resources['sp-overview'].reset),
    trackedKeywordLimit: parseInt(rawData['tracked-keywords'], 10),
    upgradedAccount: rawData.upgraded_account,
    limitOptions: rawData.limit_options,
});
