// const PARSED_DATA_EXAMPLE = [{
//     cpc: 2.82,
//     estimatedTraffic: 24600,
//     id: '2s3a1hbjd123asd31',
//     keyword: 'hotels',
//     ppc: 71,
//     type: 0,
//     rank: Defaults.NO_VALUE,
//     rankUpdatedAt: 154232000,
//     searchCount: 1500000,
//     serpPosition: 3,
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     }
//     monthlySearchVolumes: [{
//         count: 2400,
//         month: 11,
//         year: 2015
//     }]
// }];

// const PARSED_PARAMS_DATA_EXAMPLE = {
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     },
//     urlType: 'RootDomain'
// }

import { ANY_LANGUAGE } from 'mangools-commons/lib/constants/Languages';

import {
    computeTrendPercent,
    parseFloatWithNVFallback,
    parseIntWithNVFallback,
    parseLocationLabel,
    parseMSVWithoutCountDefault,
} from 'sources/parsers/commons';

import UrlTypes from 'constants/UrlTypes';
import { propOr } from 'ramda';

const parseMonthlySearchVolumes = rawVolumes =>
    rawVolumes.map(item => ({
        count: parseIntWithNVFallback(item[2]),
        month: parseInt(item[1], 10),
        year: parseInt(item[0], 10),
    }));

const parseLocation = rawLocation => ({
    code: rawLocation.code,
    id: parseInt(rawLocation._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawLocation.label),
});

const parseUrlType = rawUrlType => {
    switch (rawUrlType) {
        case 'URL': {
            return UrlTypes.EXACT_URL;
        }
        case 'SubDomain': {
            return UrlTypes.SUBDOMAIN;
        }
        case 'RootDomain': {
            return UrlTypes.ROOT_DOMAIN;
        }
        default: {
            return UrlTypes.EXACT_URL;
        }
    }
};

export const parseParams = rawData => ({
    location: parseLocation(rawData.location),
    urlType: parseUrlType(rawData.url_type),
});

export const parse = rawData =>
    rawData?.keywords?.map(item => {
        const msv = propOr([], 'msv', item);
        const monthlySearchVolumes = parseMonthlySearchVolumes(msv);
        const msvTrends = computeTrendPercent(parseMSVWithoutCountDefault(msv));
        return {
            cpc: parseFloatWithNVFallback(item?.cpc),
            estimatedTraffic: parseIntWithNVFallback(item?.et),
            id: item._id, // eslint-disable-line no-underscore-dangle
            keyword: item.kw,
            keywordType: 0,
            language: ANY_LANGUAGE,
            location: parseLocation(item.location),
            locationCode: item?.locationCode,
            monthlySearchVolumes,
            msvTrends,
            ppc: parseIntWithNVFallback(item?.ppc),
            rank: parseIntWithNVFallback(item?.seo),
            rankUpdatedAt: parseIntWithNVFallback(item?.seo_ts),
            searchCount: parseIntWithNVFallback(item?.sv),
            searchCountLatest: parseIntWithNVFallback(item?.sv_current),
            serpPosition: parseIntWithNVFallback(item?.position),
        };
    });
