import { spawn, takeLatest, select, race, put, call, delay } from 'redux-saga/effects';
import ActionTypes from 'constants/ActionTypes';
import { isNil } from 'ramda';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import {
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';

import { accessTokenSelector } from 'selectors/userSelectors';

import {
    fetchingSerpwatchTrackingsAction,
    receivedSerpwatcherTrackingsAction,
    errorSerpwatcherTrackingsAction,
} from 'actions/dataActions';

import { handleUncaught, handleError, logError } from 'sagas/errorSagas';

import SerpwatcherTrackingSource from 'sources/SerpwatcherTrackingSource';

import Strings from 'constants/Strings';

const fetchSerpwatcherTrackings = handleUncaught(
    function* fetchSerpwacherTrackings(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingSerpwatchTrackingsAction());

            const { result, _timeout } = yield race({
                result: call(SerpwatcherTrackingSource.getData, { accessToken }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedSerpwatcherTrackingsAction(payload));
                } else {
                    const handler = handleError({
                        action,
                        payload,
                        actionName: 'FetchSerpwatcherTrackingsSaga',
                        retrying,
                        errorAction: errorSerpwatcherTrackingsAction,
                        revertOptimisicUpdateAction: null,
                        saga: fetchSerpwacherTrackings,
                        failureMessage: Strings.messages.failure.fetch_serpwatcher_trackings,
                    });

                    yield call(handler);
                }
            } else {
                yield put(errorSerpwatcherTrackingsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchSerpwatcherTrackingsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorSerpwatcherTrackingsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

function* watchSerpwatcherTrackingsRequests() {
    yield takeLatest(ActionTypes.DATA_SERPWATCHER_TRACKINGS_REQUESTED, fetchSerpwatcherTrackings);
}

export function* watchOtherRequests() {
    yield spawn(watchSerpwatcherTrackingsRequests);
}
