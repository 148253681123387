import update from 'immutability-helper';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const currenciesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_CURRENCY_RATES_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_CURRENCY_RATES_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_CURRENCY_RATES_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload.currencies },
            });
        }
        default:
            return state;
    }
};

export default currenciesReducer;
