import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import isNil from 'ramda/src/isNil';
import hasPath from 'ramda/src/hasPath';

import ActionTypes from 'constants/ActionTypes';

export const DEFAULT_COMPETITOR_ROWS_MAX_LIMIT = 2000;
export const DEFAULT_LIST_KEYWORDS_MAX_LIMIT = 10000;
export const DEFAULT_RELATED_ROWS_MAX_LIMIT = 700;

const initialState = {
    accessToken: null,
    createdAt: null,
    email: null,
    error: {
        status: null,
        text: null,
    },
    unleashSession: null,
    fetched: false,
    fetching: false,
    id: null,
    limits: {
        competitorRowsLimit: 0,
        serpRequestRemaining: 0,
        fetched: false,
        fetching: false,
        importLimit: 0,
        relatedRowsLimit: 0,
        trackedKeywordLimit: 0,
        upgradedAccount: false,
        limitOptions: [],
        error: {
            status: null,
            text: null,
        },
    },
    loggedIn: false,
    plan: null,
    ssoTicket: null,
    loginToken: null,
    isSubUser: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_USER_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_DATA_RECEIVED: {
            const hasAccessToken = isNil(action.payload.accessToken) === false;

            return update(state, {
                accessToken: { $set: action.payload.accessToken },
                createdAt: { $set: action.payload.createdAt },
                email: { $set: action.payload.email },
                isSubUser: { $set: action.payload.isSubUser },
                fetched: { $set: true },
                fetching: { $set: false },
                id: { $set: action.payload.id },
                loggedIn: { $set: hasAccessToken },
                plan: { $set: action.payload.plan },
                ssoTicket: { $set: !hasAccessToken ? null : state.ssoTicket },
                loginToken: { $set: !hasAccessToken ? null : state.loginToken },
            });
        }
        case ActionTypes.DATA_USER_DATA_ERROR: {
            return update(state, {
                fetched: { $set: false },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_DATA_SKIPPED: {
            return update(state, {
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_AUTH_TOKENS_RECEIVED: {
            const { ssoTicket, loginToken } = action.payload;

            return update(state, {
                ssoTicket: { $set: ssoTicket },
                loginToken: { $set: loginToken },
            });
        }
        case ActionTypes.DATA_USER_CHECK_STATUS_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_FETCHING: {
            return update(state, {
                limits: {
                    fetching: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_RECEIVED: {
            return update(state, {
                limits: {
                    competitorRowsLimit: { $set: action.payload.competitorRowsLimit },
                    fetched: { $set: true },
                    fetching: { $set: false },
                    importLimit: { $set: action.payload.relatedRowsLimit },
                    relatedRowsLimit: { $set: action.payload.relatedRowsLimit },
                    trackedKeywordLimit: { $set: action.payload.trackedKeywordLimit },
                    upgradedAccount: { $set: action.payload.upgradedAccount },
                    serpRequestRemaining: { $set: action.payload.serpRemaining },
                    limitOptions: { $set: action.payload.limitOptions },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_ERROR: {
            return update(state, {
                limits: {
                    serpRequestRemaining: { $set: action.payload.serpRemaining },
                    fetched: { $set: true },
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                    },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_SKIPPED: {
            return update(state, {
                limits: {
                    fetched: { $set: true },
                    fetching: { $set: false },
                },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return update(initialState, {
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_SET_UNLEASH_SESSION: {
            return update(state, {
                unleashSession: { $set: action.payload.unleashSession },
            });
        }
        case REHYDRATE: {
            return update(state, {
                ssoTicket: {
                    $set: hasPath(['user', 'ssoTicket'], action.payload) ? action.payload.user.ssoTicket : null,
                },
                loginToken: {
                    $set: hasPath(['user', 'loginToken'], action.payload) ? action.payload.user.loginToken : null,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default userReducer;
