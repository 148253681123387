import update from 'immutability-helper';
import isNil from 'ramda/src/isNil';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    query: null,
    languageId: null,
    locationId: null,
};

const paramsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PARAMS_SET: {
            return update(state, {
                query: { $set: action.payload.query },
                languageId: { $set: action.payload.languageId },
                locationId: { $set: action.payload.locationId },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            const firstDataItem = action.payload.data[0];

            return update(state, {
                query: {
                    $set: !isNil(firstDataItem) ? firstDataItem.keyword : null,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default paramsReducer;
