import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { bool, arrayOf, func, number } from 'prop-types';
import NumberFormatterService from 'mangools-commons/lib/services/NumberFormatterService';
import { find, propEq, isNil } from 'ramda';
import { useDispatch } from 'react-redux';

import { DEFAULT_LIST_KEYWORDS_MAX_LIMIT } from 'reducers/userReducer';

import KeywordListItems from 'components/messages/addToListMessage/KeywordListItems';

import { gtmTrack } from 'actions/analyticsActions';

import { analyticsEvents, analyticsActions } from 'constants/analytics';

import ListType from 'types/ListType';

const useFilteredState = (initialValue, search = '', data) => {
    const [value, setValue] = useState(initialValue);
    const prevValue = useRef(initialValue);

    useEffect(() => {
        if (search.length === 0) {
            prevValue.current = value;
        }
    }, [value]);

    useEffect(() => {
        if (data.length > 0 && search.length > 0) {
            setValue(data[0].id);
        } else if (search.length === 0) {
            setValue(prevValue.current);
        }
    }, [search]);

    return [value, setValue];
};

const AddToListMessageExisting = React.memo(props => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const sanitizedSearch = search.trim().toLowerCase();

    const filterListData = () => {
        const { listData } = props;
        if (sanitizedSearch.length === 0) {
            return listData;
        } else {
            return listData.filter(({ name }) => name.toLowerCase().includes(sanitizedSearch));
        }
    };

    const filteredListData = useMemo(() => filterListData(), [props.listData, sanitizedSearch]);
    const [selectedListId, setSelectedListId] = useFilteredState(props.firstList.id, search, filteredListData);

    const handleSearchChange = value => setSearch(value);

    const getMeetsKwLimit = listId => {
        if (!isNil(selectedListId)) {
            const list = find(propEq('id', listId))(props.listData);
            return list.keywordIds.length + props.addingKeywordCount <= DEFAULT_LIST_KEYWORDS_MAX_LIMIT;
        } else {
            return true;
        }
    };

    const meetsKwLimit = getMeetsKwLimit(selectedListId);

    const updateSelectedList = newListId => {
        setSelectedListId(newListId);
    };

    const onSubmit = useCallback(
        data => {
            dispatch(
                gtmTrack({
                    action: analyticsActions.ADD_EXISTING,
                    event: analyticsEvents.ADD_TO_LIST,
                    keywordCount: props.addingKeywordCount,
                }),
            );

            props.onExistingListSelected(data);
        },
        [dispatch],
    );

    const renderCannotAddMessage = () => {
        if (!meetsKwLimit) {
            const list = find(propEq('id', selectedListId))(props.listData);
            const remaining = DEFAULT_LIST_KEYWORDS_MAX_LIMIT - list.keywordIds.length;
            const rem = remaining < 0 ? 0 : remaining; // for case when list is having more kws than limit

            if (rem === 0) {
                return (
                    <div className="mg-alert is-error mg-margin-b-15 uk-text-left font-14 uk-flex-item-none">
                        List <strong>{list.name}</strong> is already full.
                    </div>
                );
            } else {
                return (
                    <div className="mg-alert is-warning mg-margin-b-15 uk-text-left font-14 uk-flex-item-none">
                        You can add&nbsp;
                        <strong>{NumberFormatterService.simpleCommaNoDecimals(rem)}</strong> more keywords to&nbsp;
                        <strong>{list.name}</strong> list,&nbsp; but trying to add&nbsp;
                        <strong>{NumberFormatterService.simpleCommaNoDecimals(props.addingKeywordCount)}</strong>.
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    return (
        <>
            {renderCannotAddMessage()}
            <KeywordListItems
                data={filteredListData}
                onClick={updateSelectedList}
                selectedListId={selectedListId}
                search={search}
                onSearchChange={handleSearchChange}
                onSubmit={onSubmit}
                meetsKwLimit={meetsKwLimit}
                fetchingLists={props.fetchingLists}
            />
        </>
    );
});

AddToListMessageExisting.propTypes = {
    firstList: ListType.isRequired,
    addingKeywordCount: number.isRequired,
    listData: arrayOf(ListType).isRequired,
    onExistingListSelected: func.isRequired,
    fetchingLists: bool.isRequired,
};

export default AddToListMessageExisting;
