import React from 'react';
import { node, number, string, bool } from 'prop-types';
import ReactTooltip from 'rc-tooltip';

const Tooltip = React.memo(props => {
    if (props.visible) {
        return (
            <ReactTooltip
                mouseEnterDelay={props.mouseEnterDelay}
                mouseLeaveDelay={props.mouseLeaveDelay}
                overlay={props.overlayContent}
                overlayClassName={props.overlayClassName}
                placement={props.placement}
                prefixCls="tooltip"
            >
                {props.children}
            </ReactTooltip>
        );
    } else {
        return props.children;
    }
});

Tooltip.propTypes = {
    children: node.isRequired,
    mouseEnterDelay: number,
    mouseLeaveDelay: number,
    overlayClassName: string,
    overlayContent: node.isRequired,
    placement: string,
    visible: bool,
};

Tooltip.defaultProps = {
    mouseEnterDelay: 0,
    mouseLeaveDelay: 0,
    overlayClassName: 'animated-once fade-in',
    placement: 'top',
    visible: true,
};

export default Tooltip;
