const SortingColumns = {
    RELEVANCY: 'relevancy',
    SEARCH_COUNT: 'searchCount',
    SERP_POSITION: 'serpPosition',
    ESTIMATED_TRAFFIC: 'estimatedTraffic',
    CPC: 'cpc',
    PPC: 'ppc',
    RANK: 'rank',
    MSV_TRENDS: 'msvTrends',
};

export default SortingColumns;
