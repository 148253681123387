import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for persistent UI changes
 */

export const setDefaultSharedDataSource = dataSource => ({
    type: ActionTypes.UI_DEFAULTS_SHARED_DATA_SOURCE_SET,
    error: false,
    payload: dataSource,
});

export const setDefaultSharedDataSubSource = dataSubSource => ({
    type: ActionTypes.UI_DEFAULTS_SHARED_DATA_SUB_SOURCE_SET,
    error: false,
    payload: dataSubSource,
});

export const setDefaultSharedCurrentKeywordGraph = graphType => ({
    type: ActionTypes.UI_DEFAULTS_SHARED_CURRENT_KEYWORD_GRAPH_SET,
    error: false,
    payload: graphType,
});

export const setDefaultGoogleQuery = query => ({
    type: ActionTypes.UI_DEFAULTS_GOOGLE_QUERY_SET,
    error: false,
    payload: query,
});

export const setDefaultGoogleLanguage = language => ({
    type: ActionTypes.UI_DEFAULTS_GOOGLE_LANGUAGE_SET,
    error: false,
    payload: language,
});

export const setDefaultGoogleLocation = location => ({
    type: ActionTypes.UI_DEFAULTS_GOOGLE_LOCATION_SET,
    error: false,
    payload: location,
});

export const setDefaultCompetitorRowExpanded = expanded => ({
    type: ActionTypes.UI_DEFAULTS_COMPETITOR_ROW_EXPANDED_SET,
    error: false,
    payload: expanded,
});

export const setDefaultKeywordsByDomainQuery = query => ({
    type: ActionTypes.UI_DEFAULTS_COMPETITOR_QUERY_SET,
    error: false,
    payload: query,
});

export const setDefaultKeywordsByDomainLocation = location => ({
    type: ActionTypes.UI_DEFAULTS_COMPETITOR_LOCATION_SET,
    error: false,
    payload: location,
});
