import forEach from 'ramda/src/forEach';
import isNil from 'ramda/src/isNil';
import { ALPHABET, PREPOSITIONS, QUESTION_WORDS } from 'constants/Dictionary';

// From `base` keyword will generate derived keywords
// [ 'base', 'base a', 'base b', ..., 'a base', 'b base', ... ]
export function generateKeywords(base, languageCode) {
    const queries = [];

    // Put letters after base keyword
    forEach(letter => queries.push(`${base} ${letter}`), ALPHABET);

    // Put prepositions after keyword if available for `languageCode`
    const prepositions = PREPOSITIONS[languageCode];
    if (!isNil(prepositions)) {
        forEach(preposition => queries.push(`${base} ${preposition} `), prepositions);
    }

    // Put letters before base keyword (usually not very good results)
    //
    // NOTE: We've remove the space between letter and base as it looks
    // to give better results.
    //
    forEach(letter => queries.push(`${letter}${base}`), ALPHABET);
    return queries;
}

export function generateQuestions(base, languageCode) {
    const queries = [];

    // Put question words before keyword if available for `languageCode`
    const words = QUESTION_WORDS[languageCode];
    if (!isNil(words)) {
        forEach(word => queries.push(`${word} ${base} `), words);
    }

    return queries;
}
