import React from 'react';
import { string } from 'prop-types';

export function ListCreatedAlert({ listName }) {
    return (
        <>
            <span className="uk-flex">
                List&nbsp;
                <strong className="mg-truncate uk-flex-item-auto">{listName}&nbsp;</strong>
            </span>
            was successfully created
        </>
    );
}

ListCreatedAlert.propTypes = {
    listName: string.isRequired,
};

export function ListUpdatedAlert({ listName }) {
    return (
        <>
            List was successfully renamed to <br />
            <strong className="mg-truncate uk-display-inline-block">{listName}</strong>
        </>
    );
}

ListUpdatedAlert.propTypes = {
    listName: string.isRequired,
};
