const LAST_KNOWN_VALUES = 'LAST_KNOWN_VALUES';
const LAST_12_MONTHS = 'LAST_12_MONTHS';
const LAST_6_MONTHS = 'LAST_6_MONTHS';
const LAST_3_MONTHS = 'LAST_3_MONTHS';

export default {
    [LAST_KNOWN_VALUES]: LAST_KNOWN_VALUES,
    [LAST_12_MONTHS]: LAST_12_MONTHS,
    [LAST_6_MONTHS]: LAST_6_MONTHS,
    [LAST_3_MONTHS]: LAST_3_MONTHS,
};
