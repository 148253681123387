import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    export: {
        visibility: false,
    },
    help: {
        visibility: false,
    },
    links: {
        visibility: false,
    },
    listDetailActions: {
        visibility: false,
    },
    user: {
        visibility: false,
    },
};

const dropdownsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DROPDOWNS_HELP_CLOSE: {
            return update(state, {
                help: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_HELP_SHOW: {
            return update(state, {
                help: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LIST_ACTIONS_CLOSE: {
            return update(state, {
                listActions: {
                    visibleId: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LIST_ACTIONS_SHOW: {
            return update(state, {
                listActions: {
                    visibleId: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LIST_DETAIL_ACTIONS_CLOSE: {
            return update(state, {
                listDetailActions: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LIST_DETAIL_ACTIONS_SHOW: {
            return update(state, {
                listDetailActions: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LINKS_CLOSE: {
            return update(state, {
                links: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LINKS_SHOW: {
            return update(state, {
                links: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_USER_CLOSE: {
            return update(state, {
                user: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_USER_SHOW: {
            return update(state, {
                user: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_EXPORT_SHOW: {
            return update(state, {
                export: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default dropdownsReducer;
