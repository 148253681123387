import isNil from 'ramda/src/isNil';
import any from 'ramda/src/any';
import all from 'ramda/src/all';
import toLower from 'ramda/src/toLower';
import compose from 'ramda/src/compose';
import trim from 'ramda/src/trim';
import Defaults from 'mangools-commons/lib/constants/Defaults';

import { OR } from 'constants/LogicOperators';
import ListPresenceTypes from 'constants/ListPresenceTypes';
import KeywordSourceTypes from 'constants/KeywordSourceTypes';
import searchVolumeTimeframeTypes from 'constants/SearchVolumeTimeframeTypes';
import { getRelativeKD } from '../utils/relativeKD';

/**
 * Filter service for result list data.
 */
class ResultFilterService {
    /**
     * Main filter function.
     *
     * @param {data: Arrays} Array of data.
     * @param {settings: Object} Settings object.
     * @param {keywordsInListsMapSelector: Object} Map object where keys are
     * keywords ids and value is an array on lists in which that kw is contained.
     * @return {Array} Filtered data.
     */
    static filter({ quickSettings, ...args }) {
        const filteredData = ResultFilterService.applyFilters(args);

        return ResultFilterService.applyQuickFilters(filteredData, quickSettings);
    }

    static applyFilters({
        data,
        keywordsInListsMap,
        keywordSource,
        settings,
        filterActive,
        currency,
        selectedSearchVolumeTimeframe,
        urlDataLps,
        relativeKdActive,
    }) {
        if (filterActive === true) {
            // Filtering list presence
            const filteredListPresence = this.filterListPresence({ data, keywordsInListsMap, settings });
            // Filtering min values
            const filteredFrom = this.filterFromValues({
                data: filteredListPresence,
                keywordSource,
                settings,
                currency,
                selectedSearchVolumeTimeframe,
                urlDataLps,
                relativeKdActive,
            });
            // Filtering to values
            const filteredTo = this.filterToValues({
                data: filteredFrom,
                keywordSource,
                settings,
                currency,
                selectedSearchVolumeTimeframe,
                urlDataLps,
                relativeKdActive,
            });
            // Filtering included/excluded
            const filteredInEx = this.filterInEx({ data: filteredTo, settings });

            return filteredInEx;
        } else {
            return data;
        }
    }

    static applyQuickFilters(data, quickSettings) {
        const { search } = quickSettings;

        if (search.length > 0) {
            const formattedSearchValue = compose(toLower, trim)(search);
            return data.filter(({ keyword }) => keyword.includes(formattedSearchValue));
        } else {
            return data;
        }
    }

    // Filtering list presence
    static filterListPresence({ data, keywordsInListsMap, settings }) {
        if (settings.listPresenceType === ListPresenceTypes.IN_LISTS) {
            return data.filter(kw => !isNil(keywordsInListsMap[kw.id]));
        } else if (settings.listPresenceType === ListPresenceTypes.NOT_IN_LISTS) {
            return data.filter(kw => isNil(keywordsInListsMap[kw.id]));
        } else {
            return data;
        }
    }

    // Filtering min values
    static filterFromValues({
        data,
        keywordSource,
        settings,
        currency,
        selectedSearchVolumeTimeframe,
        urlDataLps,
        relativeKdActive,
    }) {
        let fromFiltered = data;

        if (settings.wordCountFrom > 0) {
            // Handle word count value
            fromFiltered = fromFiltered.filter(item => this.wordCount(item.keyword) >= settings.wordCountFrom);
        }

        // Handle cpc value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.cpcFrom === 0) {
                return true; // Pass all (also no cpc)
            } else {
                const keywordCpc = item.cpc * currency.rate;

                return keywordCpc >= settings.cpcFrom;
            }
        });

        // Handle rank value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.rankFrom === 0) {
                return true; // Pass all (also no rank)
            } else {
                if (relativeKdActive) {
                    return getRelativeKD(item.rank, urlDataLps) >= settings.rankFrom;
                }
                return item.rank >= settings.rankFrom;
            }
        });

        // Handle ppc value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.ppcFrom === 0) {
                return true; // Pass all (also no ppc)
            } else {
                return item.ppc >= settings.ppcFrom;
            }
        });

        // Handle searchCount value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.searchCountFrom === 0) {
                return true; // Pass all (also no searchCount)
            } else if (keywordSource === KeywordSourceTypes.COMPETITOR) {
                return (
                    item.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_12_MONTHS] >= settings.searchCountFrom
                );
            } else {
                return item.searchVolumesByTimeframe[selectedSearchVolumeTimeframe] >= settings.searchCountFrom;
            }
        });

        if (keywordSource === KeywordSourceTypes.COMPETITOR) {
            // Handle estimatedTraffic
            fromFiltered = fromFiltered.filter(item => {
                if (settings.estimatedTrafficFrom === 0) {
                    return true; // Pass all (also no estimatedTraffic)
                } else {
                    return item.estimatedTraffic >= settings.estimatedTrafficFrom;
                }
            });

            // Handle serpPosition
            fromFiltered = fromFiltered.filter(item => {
                if (settings.serpPositionFrom === 0) {
                    return true; // Pass all (also no serpPosition)
                } else {
                    return item.serpPosition >= settings.serpPositionFrom;
                }
            });
        }

        return fromFiltered;
    }

    // Filtering max values
    static filterToValues({
        data,
        keywordSource,
        settings,
        currency,
        selectedSearchVolumeTimeframe,
        urlDataLps,
        relativeKdActive,
    }) {
        let filteredTo = data;
        const { cpcTo, ppcTo, estimatedTrafficTo, serpPositionTo, rankTo, searchCountTo, wordCountTo } = settings;

        if (!isNil(cpcTo)) {
            filteredTo = filteredTo.filter(item => {
                const keywordCpc = item.cpc * currency.rate;

                return keywordCpc <= cpcTo;
            });
        }

        if (!isNil(ppcTo)) {
            filteredTo = filteredTo.filter(item => item.ppc <= ppcTo);
        }

        if (!isNil(rankTo)) {
            filteredTo = filteredTo.filter(item => {
                if (relativeKdActive) {
                    return getRelativeKD(item.rank, urlDataLps) <= rankTo;
                }
                return item.rank <= rankTo && item.rank !== Defaults.NO_RANK;
            });
        }

        if (!isNil(searchCountTo)) {
            if (keywordSource === KeywordSourceTypes.COMPETITOR) {
                filteredTo = filteredTo.filter(
                    item => item.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_12_MONTHS] <= searchCountTo,
                );
            } else {
                filteredTo = filteredTo.filter(
                    item => item.searchVolumesByTimeframe[selectedSearchVolumeTimeframe] <= searchCountTo,
                );
            }
        }

        if (!isNil(wordCountTo)) {
            filteredTo = filteredTo.filter(item => this.wordCount(item.keyword) <= wordCountTo);
        }

        if (keywordSource === KeywordSourceTypes.COMPETITOR) {
            if (!isNil(estimatedTrafficTo)) {
                filteredTo = filteredTo.filter(item => item.estimatedTraffic <= estimatedTrafficTo);
            }

            if (!isNil(serpPositionTo)) {
                filteredTo = filteredTo.filter(item => item.serpPosition <= serpPositionTo);
            }
        }

        return filteredTo;
    }

    // Filtering included/excluded keywords
    static filterInEx({ data, settings }) {
        let filteredInEx = data;

        const included = settings.includedKW;
        const excluded = settings.excludedKW;
        const { includedOperator, excludedOperator } = settings;

        if (included.length > 0) {
            filteredInEx = filteredInEx.filter(item =>
                this.includes({
                    keyword: item.keyword,
                    list: included,
                    operator: includedOperator,
                }),
            );
        }

        if (excluded.length > 0) {
            filteredInEx = filteredInEx.filter(
                item =>
                    !this.includes({
                        keyword: item.keyword,
                        list: excluded,
                        operator: excludedOperator,
                    }),
            );
        }

        return filteredInEx;
    }

    // Returns true if keyword is present in list of included keywords
    static includes({ keyword, list, operator }) {
        if (operator.label === OR.label) {
            return any(item => keyword.indexOf(toLower(item)) !== -1)(list);
        } else {
            return all(item => keyword.indexOf(toLower(item)) !== -1)(list);
        }
    }

    // Returns number of words in keyword
    static wordCount(keyword) {
        return keyword.split(/\s+/).length;
    }
}

export default ResultFilterService;
