import React from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ANYWHERE } from 'mangools-commons/lib/constants/Locations';

function LocationFlag({ label, code }) {
    if (label === ANYWHERE.label) {
        return <FontAwesomeIcon icon="map-marker-alt" />;
    } else {
        return <span className={`flag ${code}`} style={{ marginLeft: '-7px !important' }} />;
    }
}

LocationFlag.propTypes = {
    code: string,
    label: string.isRequired,
};

export default LocationFlag;
