import { ANYWHERE } from 'mangools-commons/lib/constants/Locations';
import { ANY_LANGUAGE } from 'mangools-commons/lib/constants/Languages';

import {
    parseIntWithNVFallback,
    parseFloatWithNVFallback,
    parseLocationLabel,
    parseMSV,
} from 'sources/parsers/commons';
import { pathOr } from 'ramda';

export const parseIndex = rawData =>
    rawData.map(item => ({
        createdAt: item.created_at,
        id: item._id, // eslint-disable-line no-underscore-dangle
        keywordIds: item.keyword_ids,
        name: item.name,
        updatedAt: item.updated_at || item.created_at, // NOTE: Fallback for old lists
        userId: item.user_id,
    }));

export const parseCreate = rawData => ({
    createdAt: rawData.created_at,
    id: rawData._id, // eslint-disable-line no-underscore-dangle
    keywordIds: rawData.keyword_ids,
    name: rawData.name,
    updatedAt: rawData.updated_at,
    userId: rawData.user_id,
});

const parseList = rawList => ({
    id: rawList._id, // eslint-disable-line no-underscore-dangle
    name: rawList.name,
});

const parseLocation = rawLocation => ({
    code: rawLocation.code,
    id: parseInt(rawLocation._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawLocation.label),
});

const parseKeywords = rawKeywords =>
    rawKeywords.map(item => ({
        cpc: parseFloatWithNVFallback(item.cpc),
        createdAt: item.ts,
        id: item._id, // eslint-disable-line no-underscore-dangle
        keyword: item.kw,
        language: ANY_LANGUAGE,
        location: item.location ? parseLocation(item.location) : ANYWHERE, // TODO: Remove
        monthlySearchVolumes: parseMSV(pathOr([], ['msv'], item)),
        ppc: parseIntWithNVFallback(item.ppc),
        rank: parseIntWithNVFallback(item.seo),
        rankUpdatedAt: parseIntWithNVFallback(item.seo_ts),
        searchCount: parseIntWithNVFallback(item.sv),
    }));

export const parseDetail = rawData => ({
    keywords: parseKeywords(rawData.keywords),
    list: parseList(rawData.list),
});
