import update from 'immutability-helper';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    active: false,
};

const relativeKdReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_RELATIVE_KD_ACTIVE_SET: {
            return update(state, {
                active: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_RELATIVE_KD_DROPDOWN_TOGGLE: {
            return update(state, {
                visibility: {
                    $apply: visibility => !visibility,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default relativeKdReducer;
