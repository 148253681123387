import config from 'appConfig';
import Rollbar from 'rollbar';
import { IGNORED_MESSAGES } from 'mangools-commons/lib/constants/Rollbar';

// Error handling using rollbar
// https://rollbar.com/docs/notifier/rollbar.js/configuration

const rollbarConfig = {
    accessToken: config.ROLLBAR_API_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    hostSafeList: ['mangools.com', 'mnglsbeta.com'],
    payload: {
        environment: config.APP_ENV,
        client: {
            source_map_enabled: true,
            code_version: config.APP_VERSION,
            guess_uncaught_frames: true,
        },
    },
    ignoredMessages: IGNORED_MESSAGES,
};

const rollbar = Rollbar.init(rollbarConfig);

export default rollbar;
