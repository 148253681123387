import { createSelector } from 'reselect';
import queryString from 'query-string';
import omit from 'ramda/src/omit';

export const currentRouteSelector = state => state.routing.locationBeforeTransitions.pathname;
export const currentQuerySelector = state => state.routing.locationBeforeTransitions.query;

export const signInRedirectSelector = createSelector([currentRouteSelector, currentQuerySelector], (route, query) =>
    queryString.stringifyUrl({
        url: `${process.env.APPS_ROOT_HOST}${route}`,
        query: omit(['sso_ticket', 'login_token'], query),
    }),
);
