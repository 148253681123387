import fetchJsonp from 'fetch-jsonp';
import queryString from 'query-string';
import { parse } from 'sources/parsers/GoogleParser';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';

// const BASE_URL = 'https://suggestqueries.google.com/complete/search';
const BASE_URL = 'https://www.google.com/complete/search';

/**
 * Data source class handling communication with google autocomplete API using JSONP.
 */
class GoogleSource {
    static getData({ keyword, languageCode, locationCode }) {
        const query = queryString.stringify({
            q: keyword,
            hl: languageCode,
            gl: locationCode,
            output: 'search',
            client: 'chrome',
            r: Date.now(),
        });

        const requestURL = `${BASE_URL}?${query}`;

        return fetchJsonp(requestURL)
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return {
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: response.statusText,
                        },
                    };
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.TOO_MANY_REQUESTS,
                    text: ex.toString(),
                },
            }));
    }
}

export default GoogleSource;
