// const PARSED_DATA_EXAMPLE = [{
//     id: '2s3a1hbjd123asd31',
//     keyword: 'hotels',
//     searchCount: 1500000,
//     cpc: 2.82,
//     ppc: 71,
//     rank: Defaults.NO_VALUE,
//     rankUpdatedAt: 154232000,
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     }
//     monthlySearchVolumes: [{
//         count: 2400,
//         month: 11,
//         year: 2015
//     }]
// }];

// const PARSED_PARAMS_DATA_EXAMPLE = {
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     }
// }

import { pathOr } from 'ramda';

import {
    computeTrendPercent,
    parseFloatWithNVFallback,
    parseIntWithNVFallback,
    parseLocationLabel,
    parseMSV,
    parseMSVWithoutCountDefault,
} from 'sources/parsers/commons';

const parseLanguage = rawLanguage => ({
    code: rawLanguage.code,
    id: parseInt(rawLanguage._id, 10), // eslint-disable-line no-underscore-dangle
    label: rawLanguage.label,
});

const parseLocation = rawLocation => ({
    code: rawLocation.code,
    id: parseInt(rawLocation._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawLocation.label),
});

export const parseMeta = rawData => ({
    rateLimited: rawData.rate_limited,
    currencyRates: rawData.currency_rates,
});

export const parseParams = rawData => ({
    language: parseLanguage(rawData.language),
    location: parseLocation(rawData.location),
});

export const parse = rawData =>
    rawData.keywords.map(item => {
        const monthlySearchVolumes = parseMSV(pathOr([], ['msv'], item));
        const msvTrends = computeTrendPercent(parseMSVWithoutCountDefault(pathOr([], ['msv'], item)));
        return {
            cpc: parseFloatWithNVFallback(item.cpc),
            id: item._id, // eslint-disable-line no-underscore-dangle
            keyword: item.kw,
            language: parseLanguage(rawData.language),
            location: parseLocation(rawData.location),
            monthlySearchVolumes,
            ppc: parseIntWithNVFallback(item.ppc),
            rank: parseIntWithNVFallback(item.seo),
            rankUpdatedAt: parseIntWithNVFallback(item.seo_ts),
            searchCount: parseIntWithNVFallback(item.sv),
            msvTrends,
        };
    });
