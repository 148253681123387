import Urls from 'mangools-commons/lib/constants/Urls';

const PATH = process.env.APP_SUBPATH || '';

const RoutePaths = {
    ROOT: `${PATH}/`,
    DASHBOARD: `${PATH}/dashboard`,
    IMPORT: `${PATH}/import`,
    SW_NEW_TRACKING: `${Urls.SERPWATCHER_APP_URL}/new`,
};

export default RoutePaths;
