import { browserHistory } from 'react-router';
import queryString from 'query-string';
import Paths from 'mangools-commons/lib/constants/Paths';

import config from 'appConfig';
import RoutePaths from 'constants/RoutePaths';

/**
 * Browser history wrapper service.
 */
class RouterService {
    /**
     * Navigate to next page based on provided route and params.
     */
    static navigate(route, params) {
        const traslatedParams = {};

        if (route === RoutePaths.DASHBOARD) {
            traslatedParams.import_id = params.importId;
            traslatedParams.language_id = params.languageId;
            traslatedParams.list_id = params.listId;
            traslatedParams.location_id = params.locationId;
            traslatedParams.query = params.query;
            traslatedParams.source_id = params.source;
            traslatedParams.sub_source_id = params.subSource;
        }

        if (route === RoutePaths.SW_NEW_TRACKING) {
            traslatedParams.kws = params.kws;
            traslatedParams.local_list_id = params.local_list_id;
            traslatedParams.url = params.url;
            traslatedParams.location_id = params.location_id;
        }

        browserHistory.push({
            pathname: route,
            query: traslatedParams,
            state: null,
        });
    }

    static replaceWithParams(params) {
        browserHistory.replace(params);
    }

    /**
     * Navigate to root page.
     */
    static navigateToRoot() {
        browserHistory.push({
            pathname: RoutePaths.ROOT,
            query: {},
            state: null,
        });
    }

    static navigateBack() {
        browserHistory.goBack();
    }

    static isSame(pathname, routePath) {
        return pathname === routePath || pathname === `/${routePath}`;
    }

    static redirect(url, query = {}) {
        const stringifiedQuery = queryString.stringify(query);
        window.location.replace(`${url}${stringifiedQuery.length > 0 ? `?${stringifiedQuery}` : ''}`);
    }

    static redirectToAuth(redirectUrl) {
        RouterService.redirect(`${config.MANGOOLS_API_HOST}${Paths.MANGOOLS_AUTH_PATH}`, { redirect: redirectUrl });
    }
}

export default RouterService;
