import update from 'immutability-helper';

import ActionTypes from 'constants/ActionTypes';
import DataSourceTypes from '../../constants/DataSourceTypes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    currentDataParams: {
        locationId: null,
        query: null,
        source: null,
    },
};

const competitorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload.data,
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
                currentDataParams: {
                    locationId: { $set: action.payload.params.locationId },
                    query: { $set: action.payload.params.query },
                    source: { $set: DataSourceTypes.MANGOOLS_COMPETITORS },
                },
            });
        }
        case ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_SKIPPED: {
            return update(state, {
                fetching: {
                    $set: false,
                },
            });
        }

        case ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: initialState.fetching },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default competitorsReducer;
