class SearchVolumeService {
    // mirrors API implementation
    /* eslint-disable no-param-reassign, no-constant-condition, operator-assignment, eqeqeq, vars-on-top, no-var */
    static roundAverage(avg) {
        if (avg == null) {
            return null;
        }
        if (avg == 0) {
            return 0;
        }
        if (avg < 10) {
            return 10;
        }
        // Dont touch first 2 digits and then set all 0
        var number = 100;
        while (true) {
            if (avg > number * 100) {
                avg -= avg % number;
                number = number * 10;
            } else {
                break;
            }
        }

        // 10ky a 100ky ponechaj jedno cislo a vsetko ostatne su 0
        if (avg > 1000) {
            avg -= avg % 100;
        }
        if (avg > 10) {
            avg -= avg % 10;
        }
        return avg;
    }
}

export { SearchVolumeService };
