import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NoConnectionMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <FontAwesomeIcon icon="plug" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Connection problem</h3>
                    <p>
                        Looks like you have <strong>lost connection</strong> to the server
                        <br />
                        or the <strong>request was blocked by a 3rd party application</strong>
                        &nbsp;(e.g. browser extension like adBlock).
                    </p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

NoConnectionMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default NoConnectionMessage;
