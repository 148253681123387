import { SourceService } from 'mangools-commons/lib/services/SourceService';

import config from 'appConfig';

import { parse } from 'sources/parsers/SerpwatcherTrackingDataParser';

const ENDPOINT = '/trackings';

class SerpwatcherTrackingSource extends SourceService {
    static getData({ accessToken }) {
        const request = SerpwatcherTrackingSource.createRequest({ method: 'GET', parse });

        return request({
            requestURL: `${config.API_HOST}${config.SERPWATCHER_API_PATH}${ENDPOINT}`,
            headers: {
                'X-Access-Token': accessToken,
            },
        });
    }
}

export default SerpwatcherTrackingSource;
