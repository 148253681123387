export const MAX_KEYWORD_LENGTH = 80;
export const KD_CACHE_TIME_DAYS = 30;

// NOTE: Deploy TS of new KD algo
// Tuesday, September 25, 2018 11:00:00 AM
export const NEW_KD_ALGO_DEPLOY_TS = 1537866000000;

export const JUST_NOW = 'Just now';
export const KEYWORDS_COPY_SEPARATOR = '\n';

export const DEFAULT_DEBOUNCE_MS = 50;
