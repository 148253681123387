import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/lib/constants/Urls';

function UpgradeForMoreResultsMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-info">
                    <FontAwesomeIcon icon="info-circle" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">See more keyword suggestions</h3>
                    <p className="uk-margin-top uk-margin-bottom">
                        See more related and competitor keywords to find the hidden gems your{' '}
                        <strong>competitors are missing!</strong>
                    </p>
                    <a
                        className="mg-btn is-green uk-text-bold"
                        href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?ref=fake-kws-msg-app-kw`}
                        rel="noppener"
                        target="_blank"
                    >
                        Upgrade now
                    </a>
                    <p className="font-14 color-grey">Stay cool, we have a 48h money back guarantee!</p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

UpgradeForMoreResultsMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default UpgradeForMoreResultsMessage;
