import { path, reverse, sortBy, prop, isNil, pipe } from 'ramda';

import SortingTypes from 'constants/SortingTypes';
import SortingColumns from 'constants/SortingColumns';

/**
 * Sorting service for result list data.
 */
class ResultSorterService {
    /**
     * Main sort function.
     *
     * @param {data: Array} Array of data.
     * @param {settings: Object} Sorting column and type.
     * @return {Array} Sorted data.
     */
    static sort({ data, settings, selectedSearchVolumeTimeframe }) {
        const attribute = settings.column;
        const reversed = this.getReversedFlag(settings.type, attribute);

        if (data.length === 0) {
            return data;
        } else if (isNil(attribute) || attribute === SortingColumns.RELEVANCY) {
            if (reversed === false) {
                return data;
            } else {
                return reverse(data);
            }
        } else if (attribute === SortingColumns.SEARCH_COUNT) {
            if (reversed === true) {
                return sortBy(pipe(path(['searchVolumesByTimeframe', selectedSearchVolumeTimeframe]), val => -val))(
                    data,
                );
            } else {
                return sortBy(path(['searchVolumesByTimeframe', selectedSearchVolumeTimeframe]))(data);
            }
        } else {
            const sorted = sortBy(prop(attribute))(data);

            if (reversed === true) {
                return reverse(sorted);
            } else {
                return sorted;
            }
        }
    }

    static getReversedFlag(type, attribute) {
        const reversed = type === SortingTypes.DESC;
        // Flip reversed when we deal with RELEVANCY.
        return attribute === SortingColumns.RELEVANCY ? !reversed : reversed;
    }
}

export default ResultSorterService;
