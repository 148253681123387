export const getRelativeKD = (rank: number, urlDataLps: number) => {
    const relativeKd = Math.round(rank - urlDataLps / 2);
    if (relativeKd > 100) {
        return 100;
    }

    if (relativeKd <= 0) {
        return 1;
    }

    return relativeKd;
};
