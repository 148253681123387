import { SourceService } from 'mangools-commons/lib/services/SourceService';
import HumanService from 'mangools-commons/lib/services/HumanService';

import { parse } from 'sources/parsers/KeywordsByDomainResultDataParser';

import config from 'appConfig';

const ENDPOINT = '/keywords';

class KeywordSource extends SourceService {
    static getData({ accessToken, keywordIds }) {
        const request = KeywordSource.createRequest({
            method: 'POST',
            parse: data => parse({ keywords: data }),
        });

        return request({
            requestURL: `${config.API_HOST}${config.API_PATH}${ENDPOINT}`,
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: {
                fields: 'kw',
                keyword_ids: keywordIds,
            },
        });
    }
}

export { KeywordSource };
