import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/lib/constants/Defaults';
import RankColorizerService from 'mangools-commons/lib/services/RankColorizerService';

import Tooltip from 'components/other/Tooltip';
import DateFormatterService from 'services/DateFormatterService';
import { NEW_KD_ALGO_DEPLOY_TS, KD_CACHE_TIME_DAYS } from 'constants/Other';
import { getRelativeKD } from '../../utils/relativeKD';

class KeywordDifficultyRank extends Component {
    constructor() {
        super();
        this.renderRank = this.renderRank.bind(this);
        this.renderSearchIcon = this.renderSearchIcon.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    isOutDatedRank() {
        if (isNil(this.props.updatedAt) || this.props.updatedAt === Defaults.NO_VALUE) {
            return true;
        } else {
            const rankUpdatedTs = this.props.updatedAt * 1000;
            const cacheValidTs = new Date().getTime() - KD_CACHE_TIME_DAYS * 24 * 60 * 60 * 1000;

            if (rankUpdatedTs < NEW_KD_ALGO_DEPLOY_TS || rankUpdatedTs < cacheValidTs) {
                // KD is either calculated before algo update or is older than 30 days (cache time)
                return true;
            } else {
                // KD is fresh
                return false;
            }
        }
    }

    renderTooltip() {
        let dateString = '';

        if (isNil(this.props.updatedAt) || this.props.updatedAt === Defaults.NO_VALUE) {
            dateString = Defaults.NOT_AVAILABLE;
        } else {
            dateString = DateFormatterService.relativeAgoOrBasicYear(this.props.updatedAt * 1000);
        }

        return (
            <span>
                Last calculated: <strong>{dateString}</strong>
            </span>
        );
    }

    renderRank() {
        const classes = classnames('mg-rank', RankColorizerService.getColor(this.props.rank));
        return (
            <Tooltip overlayContent={this.renderTooltip()} placement={this.props.tooltipPlacement}>
                <span className={classes} style={{ opacity: this.isOutDatedRank() ? 0.5 : 1.0 }}>
                    <strong>{this.props.rank}</strong>
                </span>
            </Tooltip>
        );
    }

    renderRelativeKdOrRank() {
        const { relativeKdActive, rank, urlDataLps } = this.props;

        if (relativeKdActive) {
            if (
                rank !== Defaults.NO_VALUE ||
                rank !== Defaults.NOT_AVAILABLE ||
                urlDataLps !== Defaults.NO_VALUE ||
                urlDataLps !== Defaults.NOT_AVAILABLE
            ) {
                const classes = classnames('mg-rank', RankColorizerService.getColor(getRelativeKD(rank, urlDataLps)));
                return (
                    <Tooltip overlayContent={this.renderTooltip()} placement={this.props.tooltipPlacement}>
                        <span className={classes} style={{ opacity: this.isOutDatedRank() ? 0.5 : 1.0 }}>
                            <strong>{getRelativeKD(rank, urlDataLps)}</strong>
                        </span>
                    </Tooltip>
                );
            } else {
                return this.renderRank();
            }
        } else {
            return this.renderRank();
        }
    }

    renderSearchIcon() {
        const classes = classnames('mg-rank', 'mg-no-rank');

        return (
            <span className={classes}>
                <FontAwesomeIcon icon="search" />
            </span>
        );
    }

    renderLoading() {
        const classes = classnames('mg-rank', 'mg-no-rank');

        return (
            <span className={classes}>
                <span className="mg-preloader-block uk-width-2-3" />
            </span>
        );
    }

    render() {
        if (this.props.loading) {
            return this.renderLoading();
        }
        return this.props.rank > 0 ? this.renderRelativeKdOrRank() : this.renderSearchIcon();
    }
}

KeywordDifficultyRank.propTypes = {
    rank: number.isRequired,
    tooltipPlacement: string,
    updatedAt: number.isRequired,
    loading: bool,
    relativeKdActive: bool,
    urlDataLps: string,
};

export default KeywordDifficultyRank;
