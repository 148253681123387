export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const PREPOSITIONS = {
    cs: [
        'během',
        'bez',
        'díky',
        'do',
        'kromě',
        'kvůli',
        'mezi',
        'mimo',
        'místo',
        'na',
        'nad',
        'od',
        'okolo',
        'oproti',
        'po',
        'pod',
        'podél',
        'podle',
        'pomocí',
        'před',
        'pro',
        'prostřednictvím',
        'proti',
        'skrz',
        'stran',
        'vedle',
        'vůči',
        'za',
    ],
    da: [
        'af',
        'bag',
        'efter',
        'for',
        'foran',
        'fra',
        'gennem',
        'hos',
        'i',
        'iblandt',
        'ifølge',
        'langs',
        'med',
        'mellem',
        'mod',
        'om',
        'omkring',
        'over',
        'på',
        'siden',
        'til',
        'trods',
        'uden',
        'under',
        'undtagen',
        'ved',
        'ved siden af',
    ],
    de: [
        'ab',
        'an',
        'auf',
        'aus',
        'ausser',
        'ausserhalb',
        'außer',
        'außerhalb',
        'bei',
        'beim',
        'bis',
        'der',
        'des',
        'durch',
        'fuer',
        'fuern',
        'für',
        'fürn',
        'gegen',
        'gegenueber',
        'gegenüber',
        'hinter',
        'hinterm',
        'in',
        'innerhalb',
        'jenseits',
        'kontra',
        'mit',
        'nach',
        'neben',
        'ohne',
        'pro',
        'seit',
        'statt',
        'trotz',
        'ueber',
        'ueberm',
        'um',
        'unter',
        'unterm',
        'versus',
        'vom',
        'von',
        'vor',
        'vorm',
        'wegen',
        'wider',
        'während',
        'zu',
        'zum',
        'zur',
        'zwischen',
        'über',
        'überm',
    ],
    en: [
        'about',
        'after',
        'against',
        'among',
        'around',
        'as',
        'at the bottom of',
        'at the top of',
        'at',
        'before',
        'behind',
        'beneath',
        'beside',
        'between',
        'beyond',
        'but',
        'by',
        'contra',
        'despite',
        'during',
        'even',
        'except',
        'for',
        'from',
        'in between',
        'in front of',
        'in',
        'inside',
        'into',
        'like',
        'made of',
        'near',
        'next to',
        'of',
        'off',
        'on',
        'opposed to',
        'opposite of',
        'out',
        'outside',
        'over',
        'past',
        'pro',
        'since',
        'through',
        'thru',
        'till',
        'to',
        'towards',
        'under',
        'underneath',
        'until',
        'up',
        'versus',
        'via',
        'with',
        'within',
        'without',
    ],
    es: [
        'a las',
        'a',
        'al',
        'ante',
        'antes de',
        'arriba',
        'bajo',
        'cerca de',
        'con',
        'contra',
        'de la',
        'de los',
        'de',
        'del',
        'delante de',
        'dentro de',
        'desde',
        'despues de',
        'después de',
        'detras de',
        'detrás de',
        'durante',
        'en',
        'encima de',
        'enfrente de',
        'entre',
        'fuera de',
        'hacia',
        'hasta',
        'mediante',
        'para abajo',
        'para adelante',
        'para detrás',
        'para que',
        'para',
        'por dentro',
        'por separado',
        'por',
        'pro',
        'segun',
        'según',
        'sin',
        'sobre',
        'tras',
        'versus',
    ],
    fr: [
        "a l'interieur de",
        "d'après",
        "jusqu'a",
        "jusqu'à",
        "à l'intérieur de",
        'a cote de',
        'a',
        'afin de',
        'apres',
        'après',
        'au fond de',
        'au lieu de',
        'auprès de',
        'autour de',
        'avant',
        'avec',
        'chez',
        'contra',
        'contre',
        'dans',
        'de',
        'depuis',
        'depuise',
        'derriere',
        'derrière',
        'des',
        'devant',
        'dès que',
        'dès',
        'en face',
        'en',
        'entre',
        'envers',
        'environ',
        'hors de',
        'hors',
        'kontra',
        'malgre',
        'malgré',
        'moins',
        'moyennant',
        'par',
        'parmi',
        'pendant',
        'plus',
        'pour',
        'pres de',
        'pro',
        'près de',
        'sans',
        'sauf',
        'selon',
        'sous',
        'sur',
        'vers',
        'versus',
        'via',
        'voici',
        'voila',
        'voilà',
        'à côté de',
        'à',
    ],
    nl: [
        'achter',
        'beneden',
        'bij',
        'binnen',
        'boven',
        'buiten',
        'het midden',
        'in',
        'met',
        'na',
        'naar',
        'naast',
        'om',
        'onder',
        'op',
        'over',
        'rond',
        'sinds',
        'tegen',
        'tussen',
        'van',
        'voor',
        'zonder',
    ],
    pt: [
        'acima',
        'antes',
        'ao lado',
        'baixo',
        'cima',
        'circulo',
        'como',
        'de',
        'demasiado',
        'dentro',
        'depoise',
        'desde',
        'durante',
        'em',
        'em cima',
        'em frente',
        'em vez',
        'entre',
        'fora',
        'fora de',
        'mas',
        'para',
        'para baixo',
        'perto',
        'porque',
        'que',
        'sim',
    ],
    sk: [
        'bez',
        'blízko',
        'cez',
        'do',
        'ku',
        'kvôli',
        'na',
        'nad',
        'namiesto',
        'niže',
        'od',
        'okolo',
        'po',
        'pod',
        'pomocou',
        'ponad',
        'popod',
        'poza',
        'pre',
        'pred',
        'pri',
        'so',
        'spoza',
        'vo',
        'vyše',
        'zo',
        'zozadu',
    ],
};

export const QUESTION_WORDS = {
    ar: ['ماذا', 'أي', 'أين', 'لماذا', 'متى', 'من', 'لمن', 'كيف'],
    bg: ['kak', 'kakvo', 'koj', 'kolko', 'kyde', 'zashto'],
    ca: ['com', 'on', 'per què', 'quan', 'quant', 'què', 'qui'],
    cs: ['co', 'jak', 'jaký', 'kam', 'kde', 'kdo', 'kdy', 'koho', 'kolik', 'komu', 'který', 'kudy', 'proč'],
    da: [
        'hvem',
        'hvad',
        'hvorfor',
        'hvornår',
        'hvor',
        'hvis',
        'hvilken',
        'hvilket',
        'hvilke',
        'hvor til',
        'hvor fra',
        'hvordan',
    ],
    de: [
        'ab wann',
        'inwiefern',
        'wann',
        'warum',
        'was',
        'welche',
        'welchem',
        'welcher',
        'welches',
        'wem',
        'wen',
        'wer',
        'weshalb',
        'wessen',
        'weswegen',
        'wie viel',
        'wie viele',
        'wie',
        'wieso',
        'wieviel',
        'wo',
        'woher',
        'wohin',
        'wohinter',
        'womit',
        'woran',
        'worauf',
        'worin',
        'worunter',
        'wovor',
        'wozu',
    ],
    el: ['γιατί', 'ποιος', 'πού', 'πώς', 'τι'],
    en: [
        'how far',
        'how many',
        'how much',
        'how often',
        'how',
        'what',
        'when',
        'where from',
        'where to',
        'where',
        'which',
        'who',
        'whom',
        'whose',
        'why',
    ],
    es: [
        'adónde',
        'como',
        'cual',
        'cuales',
        'cuanto',
        'cuantos',
        'cuál',
        'cuáles',
        'cuándo',
        'cuántas',
        'cuánto',
        'cuántos',
        'cómo',
        'de dónde',
        'dónde',
        'por qué',
        'pregunta',
        'que',
        'quien',
        'quienes',
        'quién',
        'quiénes',
        'qué',
    ],
    et: ['kes', 'kuidas', 'kus', 'mida', 'miks', 'mis'],
    fi: ['kuka', 'miksi', 'missä', 'mitä', 'miten'],
    fil: ['ano', 'bakit', 'kung paano', 'kung saan', 'sino'],
    fr: [
        "est-ce qu'",
        "qu'",
        "qu'est-ce qu'",
        "qu'est-ce que",
        'combien',
        'comment',
        'est-ce que',
        'laquelle',
        'lequel',
        'lesquelles',
        'lesquels',
        'ou',
        'où',
        'pourquoi',
        'quand',
        'que',
        'quel',
        'quelle',
        'quelles',
        'quels',
        'qui',
        'quoi',
    ],
    he: ['איך', 'איפה', 'למה', 'מה', 'מי'],
    hi: ['kahan', 'kaise', 'kaun', 'kya', 'kyon'],
    hr: ['dokada', 'gdje', 'kada', 'kako', 'kamo', 'koliko', 'kuda', 'odakle', 'otkada', 'što', 'tko', 'zašto'],
    hu: ['hogyan', 'mi', 'mit', 'mik', 'miket', 'ki', 'kit', 'kik', 'kiket', 'miért', 'hol', 'hová'],
    is: ['hvernig', 'hvað', 'hver', 'hvers vegna', 'hvar'],
    id: ['apa', 'bagaimana', 'mana', 'mengapa', 'siapa'],
    it: ['che cosa', 'chi', 'dove', 'perché', 'quanto'],
    ja: [
        '何',
        '誰',
        'いつ',
        'だれ',
        'どう',
        'どこ',
        'どっ',
        'なぜ',
        'なに',
        'なん',
        '幾つ',
        'いかが',
        'いくら',
        'どちら',
        'どっち',
        'どなた',
        'どんな',
        'どうして',
        '何ですか',
        '誰ですか',
        'どこですか',
        'なぜですか',
        'どのようにですか',
    ],
    ko: ['왜', '누가', '어디로', '어떻게', '무엇을'],
    lv: ['kā', 'kāpēc', 'kas', 'kur'],
    lt: ['ką', 'kaip', 'kas', 'kodėl', 'kokia', 'kokiu būdu', 'koks', 'kur'],
    ms: ['apa', 'bagaimana', 'di mana', 'mengapa', 'siapa'],
    nl: ['hoe', 'hoeveel', 'waar', 'waarom', 'wanneer', 'wat', 'wat voor', 'welke', 'wie'],
    nn: ['hva', 'hvem', 'hvor', 'hvordan', 'hvorfor'],
    fa: ['chehe', 'chekonehe', 'cherā', 'kejā', 'keye'],
    pl: ['co', 'dlaczego', 'gdzie', 'jak', 'kto'],
    pt: [
        'aonde',
        'a quem',
        'com quem',
        'de onde',
        'de quem',
        'onde',
        'o que',
        'para onde',
        'por que',
        'por que não',
        'qual',
        'quando',
        'quantos',
        'sobre quem',
    ],
    ro: ['ce', 'cine', 'cum', 'de ce', 'unde'],
    ru: ['где', 'каким образом', 'кто', 'почему', 'что'],
    sk: [
        'aká',
        'aké',
        'ako',
        'aký',
        'čí',
        'čia',
        'čie',
        'čo',
        'kam',
        'kde',
        'kedy',
        'koľko',
        'kto',
        'ktorá',
        'ktoré',
        'ktorý',
        'načo',
        'odkiaľ',
        'prečo',
    ],
    sl: ['kaj', 'kako', 'kdo', 'kje', 'zakaj'],
    sr: ['где', 'зашто', 'како', 'ко', 'шта'],
    sv: ['hur', 'vad', 'var', 'varför', 'vem'],
    th: ['ได้ อย่างไร', 'อะไร', 'ใคร', 'ทำไม', 'ที่ไหน'],
    tr: ['nasıl', 'ne', 'kim', 'neden', 'nerede'],
    uk: ['де', 'хто', 'чому', 'що', 'як'],
    ur: ['kehan sai', 'keon', 'keya', 'keyon', 'keysa'],
    vi: ['ai', 'cái gì', 'làm thế nào', 'ở đâu', 'tại sao'],
    zh: ['什么', '哪里', '哪儿', '哪个', '谁', '什么时候', '为什么', '怎么'],
};
