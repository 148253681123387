import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for persistent UI changes
 */

export const requestedHideAuthTokensAction = () => ({
    type: ActionTypes.ROUTER_AUTH_TOKENS_HIDE_REQUESTED,
});

export const requestedNavigationAction = (route, params) => ({
    type: ActionTypes.ROUTER_NAVIGATION_REQUESTED,
    error: false,
    payload: { route, params },
});

export const requestedNavigationBackAction = () => ({
    type: ActionTypes.ROUTER_NAVIGATION_BACK_REQUESTED,
});
