import update from 'immutability-helper';
import { concat, isNil } from 'ramda';
import { REHYDRATE } from 'redux-persist/constants';

import searchVolumeTimeframeTypes from 'constants/SearchVolumeTimeframeTypes';
import ActionTypes from 'constants/ActionTypes';
import SortingColumns from 'constants/SortingColumns';
import SortingTypes from 'constants/SortingTypes';
import SortingStorageKeys from 'constants/SortingStorageKeys';

const initialState = {
    currentKeywordId: null,
    keywordSource: {
        id: null,
        name: null,
        type: null,
    },
    leftLoader: {
        progress: {
            count: 0,
            finished: 0,
            importing: false,
            visibility: false,
        },
    },
    selectedKeywordIds: [],
    serpExpanded: false,
    serpItemActionsDropdownVisibleId: null,
    sortingSettings: {
        column: SortingColumns.ESTIMATED_TRAFFIC,
        type: SortingTypes.DESC,
    },
    selectedSearchVolumeTimeframe: searchVolumeTimeframeTypes.LAST_KNOWN_VALUES,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DASHBOARD_CURRENT_KEYWORD_ID_SET: {
            return update(state, {
                currentKeywordId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_SET: {
            return update(state, {
                selectedKeywordIds: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT: {
            return update(state, {
                selectedKeywordIds: {
                    $apply: ids => {
                        const newIds = action.payload.filter(keywordId => !ids.includes(keywordId));

                        if (newIds.length === 0) {
                            return ids;
                        } else {
                            return concat(ids, action.payload);
                        }
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT: {
            return update(state, {
                selectedKeywordIds: {
                    $apply: ids => ids.filter(id => !action.payload.includes(id)),
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT_ALL: {
            return update(state, {
                selectedKeywordIds: {
                    $set: [],
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_SET: {
            const { column, type } = action.payload;
            localStorage.setItem(SortingStorageKeys.COLUMN, column);
            localStorage.setItem(SortingStorageKeys.DIRECTION, type);
            return update(state, {
                sortingSettings: {
                    column: {
                        $set: column,
                    },
                    type: {
                        $set: type,
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_RESET: {
            return update(state, {
                sortingSettings: {
                    $set: initialState.sortingSettings,
                },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            const firstKw = action.payload.data[0];

            return update(state, {
                currentKeywordId: {
                    $set: !isNil(firstKw) ? firstKw.id : null,
                },
            });
        }
        case ActionTypes.DATA_IMPORT_FETCHING: {
            return update(state, {
                leftLoader: {
                    progress: {
                        importing: { $set: true },
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED: {
            const firstKw = action.payload[0];

            return update(state, {
                currentKeywordId: {
                    $set: !isNil(firstKw) ? firstKw.id : null,
                },
                leftLoader: {
                    progress: {
                        importing: { $set: false },
                    },
                },
            });
        }
        case ActionTypes.DATA_IMPORT_RECEIVED: {
            const firstKw = action.payload.data[0];

            return update(state, {
                currentKeywordId: {
                    $set: !isNil(firstKw) ? firstKw.id : null,
                },
                leftLoader: {
                    progress: {
                        importing: { $set: false },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SERP_EXPAND_TOGGLE: {
            return update(state, {
                serpExpanded: {
                    $apply: expanded => !expanded,
                },
            });
        }
        case ActionTypes.DATA_RESULTS_GOOGLE_FETCHING: {
            return update(state, {
                leftLoader: {
                    progress: {
                        visibility: { $set: true },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_KEYWORD_SOURCE_SET: {
            return update(state, {
                keywordSource: {
                    id: { $set: action.payload.id },
                    name: { $set: action.payload.name },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.DATA_IMPORT_ERROR:
        case ActionTypes.DATA_RESULTS_GOOGLE_EMPTY:
        case ActionTypes.DATA_RESULTS_GOOGLE_ERROR:
        case ActionTypes.DATA_RESULTS_GOOGLE_FINISHED: {
            return update(state, {
                leftLoader: {
                    $set: initialState.leftLoader,
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_LEFT_LOADER_PROGRESS_SET: {
            return update(state, {
                leftLoader: {
                    progress: {
                        count: { $set: action.payload.count },
                        finished: { $set: action.payload.finished },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SERP_ITEM_ACTIONS_DROPDOWN_VISIBLE_ID_SET: {
            return update(state, {
                serpItemActionsDropdownVisibleId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_SELECTED_SEARCH_VOLUME_TIMEFRAME_SET: {
            return update(state, {
                selectedSearchVolumeTimeframe: { $set: action.payload },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.dashboard)) {
                return update(state, {
                    selectedSearchVolumeTimeframe: {
                        $set:
                            savedState.dashboard.selectedSearchVolumeTimeframe ||
                            initialState.selectedSearchVolumeTimeframe,
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default dashboardReducer;
