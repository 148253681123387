import React from 'react';
import Strings from 'constants/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { requestedKeywordsImportAction } from 'actions/dataActions';

import {
    resultsLastPayloadSelector,
} from 'selectors/dataSelectors';

const FailureMessageRetryBtn = ({ failureMessage }: FailureMessageRetryBtnProps) => {
    const dispatch = useDispatch();
    const resultsLastPayloadData = useSelector(resultsLastPayloadSelector);

	switch (failureMessage) {
		case Strings.messages.failure.import_error_424: {
			const retryImport = () => {
				if (resultsLastPayloadData?.keywords) {
					dispatch(requestedKeywordsImportAction(resultsLastPayloadData.keywords, resultsLastPayloadData.locationId));
				} else {
					location.reload();
				}
			}
			return (
				<button className="mg-btn is-blue is-small mg-margin-b-30" type="button" onClick={retryImport}>
                    <FontAwesomeIcon icon="sync-alt" />
                    Try again
                </button>
			)
		}
		default: return null;
	}
}

export default FailureMessageRetryBtn;

interface FailureMessageRetryBtnProps {
	failureMessage: string,
}