export const selectedSearchVolumeTimeframeTypeSelector = state => state.ui.dashboard.selectedSearchVolumeTimeframe;
export const fakeResultsDisabledSelector = state => state.data.results.fakeResultsDisabled;
export const topLevelNewsMessageVisibilitySelector = state => state.ui.messages.topLevel.newsMessage.visibility;
export const resultsDataSelector = state => state.data.results.data;
// Shared user selectors

export const competitorRowsLimitSelector = state => state.user.limits.competitorRowsLimit;
export const limitsFetchedSelector = state => state.user.limits.fetched;
export const loggedInSelector = state => state.user.loggedIn;
export const relatedRowsLimitSelector = state => state.user.limits.relatedRowsLimit;
export const ranksFetchingIdSelector = state => state.data.results.rankFetching;

// Shared UI selectors

// Messages
/* eslint-disable max-len */
export const topLevelAnnouncementMessageVisibilitySelector = state =>
    state.ui.messages.topLevel.announcement.visibility;
/* eslint-enable max-len */

export const keywordSourceResourceTypeSelector = state => state.ui.dashboard.keywordSource.type;
