import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import { forEach } from 'ramda';

import config from 'appConfig';
import rootReducer from 'reducers/rootReducer';
import { rehydrationComplete } from 'actions/dataActions';
import rootSaga from 'sagas/rootSaga';

import { ReduxAnalyticsMiddlewareService } from 'services/ReduxAnalyticsMiddlewareService';
import GTMService from 'mangools-commons/lib/services/GTMService';

// Middleware setting
const sagaMiddleware = createSagaMiddleware();
const analyticsMiddleware = ReduxAnalyticsMiddlewareService.createMiddleware(GTMService.dataLayer.push);

const middlewares = [sagaMiddleware, analyticsMiddleware];

// Constants
const LS_PREFIX = 'KWF';
const WHITELIST = ['ui', 'user'];

// Create compone enhancer with Redux devtools in development
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            maxAge: 9999,
            shouldCatchErrors: true,
        })
        : compose;
/* eslint-enable no-underscore-dangle */

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// Store configuration
const configureStore = initialState => createStore(rootReducer, initialState, enhancer);

// Get configured store
const store = configureStore();

// Run root saga
store.runSaga = sagaMiddleware.run(rootSaga);
store.close = () => store.dispatch(END);

// Save and Load transforms

const filterUiReducer = createFilter('ui', [
    'defaults.competitorMode.competitorRowExpanded',
    'defaults.competitorMode.location',
    'defaults.googleMode.language',
    'defaults.googleMode.location',
    'defaults.shared.currentKeywordGraph',
    'defaults.shared.dataSource',
    'filter.settings',
    'misc.colorScheme',
    'misc.currency',
    'dashboard.selectedSearchVolumeTimeframe',
]);

const filterUserReducer = createFilter('user', ['ssoTicket', 'loginToken']);

// Setup redux persist

persistStore(
    store,
    {
        keyPrefix: `${LS_PREFIX}_v${config.LOCAL_STORAGE_VERSION}_`,
        transforms: [filterUiReducer, filterUserReducer],
        whitelist: WHITELIST,
    },
    () => {
        store.dispatch(rehydrationComplete());
    },
);

// Utility functions

export const cleanPreviousState = () => {
    const oldKeyPrefix =
        config.LOCAL_STORAGE_VERSION > 1 ? `${LS_PREFIX}_v${config.LOCAL_STORAGE_VERSION - 1}` : LS_PREFIX;

    forEach(item => {
        const key = `${oldKeyPrefix}_${item}`;
        localStorage.removeItem(key);
    }, WHITELIST);
};

export default store;
