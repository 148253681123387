import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AppPanel from 'components/panels/AppPanel';
import HistoryPanel from 'components/panels/HistoryPanel';
import ListPanel from 'components/panels/ListPanel';

import {
    requestedListExportAction,
    requestedHistoryAction,
    requestedListsAction,
    requestedListsDeleteAction,
    requestedDeleteHistoryAction,
    requestedListsRenameAction,
} from 'actions/dataActions';

import {
    closeAppPanel,
    closeHistoryPanel,
    closeListPanel,
    setListPanelFilter,
    showTrackInSerpwatcherMessage,
} from 'actions/uiActions';

import {
    appPanelVisibilitySelector,
    historyPanelVisibilitySelector,
    listPanelFilterSelector,
    listPanelVisibilitySelector,
    trackInSerpwatcherMessageVisibilitySelector,
} from 'selectors/uiSelectors';

import { filteredAndSortedListPanelDataSelector, historyDataSelector } from 'selectors/dataSelectors';

import { requestedNavigationAction } from 'actions/routerActions';

import DataSourceTypes from 'constants/DataSourceTypes';
import DataSubSourceTypes from 'constants/DataSubSourceTypes';
import HistoryItemTypes from 'constants/HistoryItemTypes';
import RoutePaths from 'constants/RoutePaths';

import ListType from 'types/ListType';
import HistoryType from 'types/HistoryType';

class PanelContainer extends Component {
    constructor() {
        super();
        this.handleHistoryClick = this.handleHistoryClick.bind(this);
    }

    handleHistoryClick(type, { query, languageId, locationId }) {
        this.props.onCloseHistoryPanel();

        if (type === HistoryItemTypes.COMPETITOR) {
            this.props.requestNavigation(RoutePaths.DASHBOARD, {
                locationId,
                query,
                source: DataSourceTypes.MANGOOLS_COMPETITORS,
                subSource: DataSubSourceTypes.COMPETITORS_ORGANIC,
            });
        } else {
            this.props.requestNavigation(RoutePaths.DASHBOARD, {
                languageId,
                locationId,
                query,
                source: DataSourceTypes.GOOGLE,
                subSource: DataSubSourceTypes.ADWORDS_RELATED_KEYWORDS,
            });
        }
    }

    render() {
        return (
            <div>
                <AppPanel visible={this.props.appPanelVisible} onClose={this.props.onCloseAppPanel} />
                <ListPanel
                    visible={this.props.listPanelVisible}
                    data={this.props.listData}
                    exporting={this.props.exporting}
                    fetching={this.props.fetchingListData}
                    listFilter={this.props.listFilter}
                    onChangeListFilter={this.props.onChangeListFilter}
                    onClose={this.props.onCloseListPanel}
                    onDeleteList={this.props.onDeleteList}
                    onExportList={this.props.onExportList}
                    onOpen={this.props.requestListsFetch}
                    requestNavigation={this.props.requestNavigation}
                    onRenameList={this.props.onRenameList}
                    onShowTrackInSerpwatcherMessage={this.props.onShowTrackInSerpwatcherMessage}
                    trackInSerpwatcherVisible={this.props.trackInSerpwatcherVisible}
                />
                <HistoryPanel
                    data={this.props.historyData}
                    fetching={this.props.fetchingHistoryData}
                    onClick={this.handleHistoryClick}
                    onClose={this.props.onCloseHistoryPanel}
                    onOpen={this.props.requestHistoryFetch}
                    onDeleteHistory={this.props.requestHistoryDelete}
                    visible={this.props.historyPanelVisible}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appPanelVisible: appPanelVisibilitySelector(state),
    exporting: state.ui.misc.exporting,
    fetchingHistoryData: state.data.history.fetching,
    fetchingListData: state.data.lists.fetching,
    historyData: historyDataSelector(state),
    historyPanelVisible: historyPanelVisibilitySelector(state),
    listData: filteredAndSortedListPanelDataSelector(state),
    listFilter: listPanelFilterSelector(state),
    listPanelVisible: listPanelVisibilitySelector(state),
    trackInSerpwatcherVisible: trackInSerpwatcherMessageVisibilitySelector(state),
});

const mapDispatchToProps = dispatch => ({
    onChangeListFilter(newFilter) {
        dispatch(setListPanelFilter(newFilter));
    },
    onCloseAppPanel() {
        dispatch(closeAppPanel());
    },
    onCloseHistoryPanel() {
        dispatch(closeHistoryPanel());
    },
    onCloseListPanel() {
        dispatch(closeListPanel());
    },
    onDeleteList({ listId, listName }) {
        dispatch(requestedListsDeleteAction({ listId, listName }));
    },
    onExportList(listId) {
        dispatch(
            requestedListExportAction({
                listId,
            }),
        );
    },
    requestNavigation(route, params) {
        dispatch(requestedNavigationAction(route, params));
    },
    requestHistoryFetch() {
        dispatch(requestedHistoryAction());
    },
    requestListsFetch() {
        dispatch(requestedListsAction());
    },
    requestHistoryDelete() {
        dispatch(requestedDeleteHistoryAction());
    },
    onRenameList(listId, newName) {
        dispatch(requestedListsRenameAction(listId, newName));
    },
    onShowTrackInSerpwatcherMessage(listId) {
        dispatch(showTrackInSerpwatcherMessage(listId));
    },
});

PanelContainer.propTypes = {
    appPanelVisible: bool.isRequired,
    exporting: bool.isRequired,
    fetchingHistoryData: bool.isRequired,
    fetchingListData: bool.isRequired,
    historyData: arrayOf(HistoryType).isRequired,
    historyPanelVisible: bool.isRequired,
    listData: arrayOf(ListType).isRequired,
    listFilter: string.isRequired,
    listPanelVisible: bool.isRequired,
    onChangeListFilter: func.isRequired,
    onCloseAppPanel: func.isRequired,
    onCloseHistoryPanel: func.isRequired,
    onCloseListPanel: func.isRequired,
    onDeleteList: func.isRequired,
    onExportList: func.isRequired,
    requestHistoryDelete: func.isRequired,
    requestHistoryFetch: func.isRequired,
    requestListsFetch: func.isRequired,
    requestNavigation: func.isRequired,
    onRenameList: func.isRequired,
    onShowTrackInSerpwatcherMessage: func.isRequired,
    trackInSerpwatcherVisible: bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer);
