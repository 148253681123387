class ReduxAnalyticsMiddlewareService {
    static createMiddleware(callback, options) {
        const defaultOptions = {
            analyticsActionType: 'ANALYTICS_TRACK',
            ...options,
        };
        const { analyticsActionType } = defaultOptions;

        return _storeApi => next => action => {
            if (action.type === analyticsActionType) {
                callback(action.payload);

                return undefined;
            }

            return next(action);
        };
    }
}

export { ReduxAnalyticsMiddlewareService };
