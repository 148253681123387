import CSV from 'comma-separated-values';
import { concat, append, isNil, insert, reverse, isEmpty } from 'ramda';
import CSVExportService from 'mangools-commons/lib/services/CSVExportService';

import KeywordSourceTypes from 'constants/KeywordSourceTypes';
import searchVolumeTimeframeTypes from 'constants/SearchVolumeTimeframeTypes';
import { getRelativeKD } from '../utils/relativeKD';

class ExportService {
    static exportKeywords(keywords, { kwSourceType, currency, urlDataLps, relativeKdActive }) {
        const isCompetitorSourceType = kwSourceType === KeywordSourceTypes.COMPETITOR;

        let columns = [
            'Keyword',
            isCompetitorSourceType ? 'Avg. Search Volume (Last 12 months)' : 'Avg. Search Volume (Last Known Values)',
            'Location',
            `CPC/${currency.code}`,
            'PPC',
            'Keyword Difficulty',
            'Keyword interest growth (%)',
        ];

        let data = [];
        let msvDates = null;
        let searchVolumeColumns = null;
        const startEndMsv = ExportService.getStartEndMsvDate(keywords);

        if (!isNil(startEndMsv)) {
            msvDates = ExportService.getMsvDates(startEndMsv.start, startEndMsv.end);
            searchVolumeColumns = ExportService.generateSearchVolumeColumns(msvDates);
            columns = concat(columns, searchVolumeColumns);
        }

        data = keywords.map(keyword => {
            let keywordData = [
                keyword.keyword,
                CSVExportService.formatEmptyValue(
                    keyword.searchVolumesByTimeframe[
                        isCompetitorSourceType
                            ? searchVolumeTimeframeTypes.LAST_12_MONTHS
                            : searchVolumeTimeframeTypes.LAST_KNOWN_VALUES
                    ],
                ),
                keyword.location.label,
                ExportService.formatCpc(keyword.cpc, currency),
                CSVExportService.formatEmptyValue(keyword.ppc),
                CSVExportService.formatEmptyValue(
                    relativeKdActive ? getRelativeKD(keyword.rank, urlDataLps) : keyword.rank,
                ),
                CSVExportService.formatEmptyValue(keyword.msvTrends),
            ];

            if (!isNil(startEndMsv)) {
                const searchVolumeData = ExportService.setSearchVolumeData(
                    keyword.monthlySearchVolumes,
                    msvDates,
                    keyword.keyword,
                );

                keywordData = concat(keywordData, searchVolumeData);
            }

            if (isCompetitorSourceType) {
                keywordData = insert(1, keyword.serpPosition, keywordData);
                keywordData = insert(3, keyword.estimatedTraffic, keywordData);
            } else {
                const last12Months = CSVExportService.formatEmptyValue(
                    keyword.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_12_MONTHS],
                );
                const last6Months = CSVExportService.formatEmptyValue(
                    keyword.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_6_MONTHS],
                );
                const last3Months = CSVExportService.formatEmptyValue(
                    keyword.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_3_MONTHS],
                );

                keywordData = insert(2, last12Months, keywordData);
                keywordData = insert(3, last6Months, keywordData);
                keywordData = insert(4, last3Months, keywordData);
            }

            return keywordData;
        });

        if (isCompetitorSourceType) {
            columns = insert(1, 'SERP Position', columns);
            columns = insert(3, 'Estimated Visits', columns);
        } else {
            columns = insert(2, 'Avg. Search Volume (Last 12 months)', columns);
            columns = insert(3, 'Avg. Search Volume (Last 6 months)', columns);
            columns = insert(4, 'Avg. Search Volume (Last 3 months)', columns);
        }

        return CSV.encode(data, { header: columns });
    }

    static generateSearchVolumeColumns(dates) {
        return dates.map(({ month, year }) => `Search Volume ${month + 1}/${year}`);
    }

    static setSearchVolumeData(monthlySearchVolumes, dates) {
        const msvCountMap = ExportService.getMsvCountMap(monthlySearchVolumes);

        return dates.map(({ month, year }) => {
            const count = msvCountMap[ExportService.generateMsvMapKey(month, year)];

            return CSVExportService.formatEmptyValue(count);
        });
    }

    static getMsvCountMap(monthlySearchVolumes) {
        return monthlySearchVolumes.reduce((map, { count, month, year }) => {
            // eslint-disable-next-line no-param-reassign
            map[ExportService.generateMsvMapKey(month, year)] = count;

            return map;
        }, {});
    }

    static generateMsvMapKey(month, year) {
        return `${month}-${year}`;
    }

    static getMsvDates(start, end) {
        let dates = [];

        // eslint-disable-next-line prefer-destructuring, no-plusplus
        for (let year = start.year; year <= end.year; year++) {
            let startMonth = 0;
            let endMonth = 12;

            if (year === start.year) {
                startMonth = start.month;
            }

            if (year === end.year) {
                endMonth = end.month + 1;
            }

            // eslint-disable-next-line no-plusplus
            for (startMonth; startMonth < endMonth; startMonth++) {
                dates = append({ month: startMonth, year }, dates);
            }
        }

        return dates;
    }

    static getStartEndMsvDate(keywords) {
        const firstKwWithMsv = ExportService.getFirstKwWithMsv(keywords);
        const lastKwWithMsv = ExportService.getLastKwWithMsv(keywords);

        if (isNil(firstKwWithMsv) || isNil(lastKwWithMsv)) {
            return null;
        }

        let start = {
            month: firstKwWithMsv.monthlySearchVolumes[0].month,
            year: firstKwWithMsv.monthlySearchVolumes[0].year,
        };

        let end = {
            month: lastKwWithMsv.monthlySearchVolumes[0].month,
            year: lastKwWithMsv.monthlySearchVolumes[0].year,
        };

        keywords.forEach(({ monthlySearchVolumes }) => {
            monthlySearchVolumes.forEach(({ month, year }) => {
                if (year < start.year) {
                    start = { month, year };
                } else if (year === start.year) {
                    if (month < start.month) {
                        start = { month, year };
                    }
                }

                if (year > end.year) {
                    end = { month, year };
                } else if (year === end.year) {
                    if (month > end.month) {
                        end = { month, year };
                    }
                }
            });
        });

        return { start, end };
    }

    static getFirstKwWithMsv(keywords) {
        return keywords.find(({ monthlySearchVolumes }) => monthlySearchVolumes.length > 0);
    }

    static getLastKwWithMsv(keywords) {
        return reverse(keywords).find(({ monthlySearchVolumes }) => monthlySearchVolumes.length > 0);
    }

    static formatCpc(value, currency) {
        const formattedValue = CSVExportService.formatEmptyValue(value);
        if (isEmpty(formattedValue)) {
            return formattedValue;
        } else {
            return (value * currency.rate).toFixed(2);
        }
    }
}

export default ExportService;
