// String.xml like to have all UI texts in one place.
// Will help when I18n comes to mind, or just to ensure same jargon in whole app.

// TODO: migrate all string from APP here!
const Strings = {
    components: {
        common: {
            loading: 'Preparing your drink...',
        },
        other: {
            preloader_notification: {
                body: 'Looks like SERP results are taking more time to load, please wait...',
            },
        },
    },
    headers: {
        failure: {
            fetch_google_results_error: 'Google API rate limit',
            fetch_results_error_kw_by_domain_422: 'Invalid Input Detected!',
        },
    },
    messages: {
        failure: {
            create_list_error: 'There was an error while creating a new list.',
            delete_keywords_from_list_error: 'There was an error while deleting keywords from the list.',
            delete_keyword_from_list_error: 'There was an error while deleting keyword from the list.',
            delete_list_error: 'There was an error while deleting the list.',
            download_error: "We're sorry, the download is not working in this browser. Try Chrome or Firefox, please.",
            export_error: 'There was an error while exporting your data.',
            fetch_keywords_by_domain_results_error: 'There was an error while fetching keywords data.',
            fetch_competitors_results_error: 'There was an error while competitors data.',
            fetch_history_error: 'There was an error while fetching requests history data.',
            fetch_limits_error: 'There was an error while fetching your account limits data.',
            fetch_list_detail_error: 'There was an error while fetching list data.',
            fetch_lists_error: 'There was an error while fetching your lists data.',
            fetch_locations_error: 'There was an error while fetching locations data.',
            fetch_trends_error: 'There was an error while fetching Google trends data.',
            fetch_google_results_error: `There was an error while fetching Google Autocomplete data.
                                         Your IP address might be temporarily blocked by Google Autocomplete API.`,
            fetch_google_results_timeout_error: `There was an error while fetching Google Autocomplete data.
                                         We were unable to fetch the data in defined timeout period.`,
            fetch_google_results_empty_error: `No keywords were loaded from Google Autocomplete API.
                                               Make sure you set proper location and language.`,
            fetch_results_error: 'There was an error while fetching related keywords data.',
            fetch_results_error_kw_by_domain_422: `You're performing a 'Search by Domain' operation.
                                                Please ensure you enter a valid URL or domain.`,
            fetch_serp_results_error: 'There was an error while fetching SERP data.',
            import_error: 'There was an error while importing your keywords.',
            import_error_424: 'There was an error while fetching Google data. Your credits were not used.',
            logout_error: 'There was an error while trying to log you out.',
            maintenance: "We're sorry, our services are currently undergoing maintenance.",
            rename_list_error: 'There was an error while renaming the list.',
            show_list_data_error: 'There was an error while fetching keywords from your list.',
            show_list_data_not_found_error: 'The requested list does not exists.',
            footer_sorry: "We're sorry, try again later, please.",
            too_many_requests_error: "We're sorry, you exceeded the maximum allowed API requests per second.",
            update_list_limit_error: `There was an error while updating your list.
                                      Remember, You can't exceed 1,000 keywords per list.`,
            update_list_error: 'There was an error while updating your list.',
            delete_history: 'There was an error while deleting your history.',
            fetch_currencies_error: 'There was an error while fetching currencies.',
            fetch_serpwatcher_trackings: 'There was an error while fetching your SERPWatcher trackings.',
        },
    },
};

export default Strings;
