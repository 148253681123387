const CLOSE_RATE_TREHSOLD = 3;
const SHORT_SYMBOL_THRESHOLD = 3;

class CurrencyService {
    static isShortSymboled(symbol) {
        return symbol.length < SHORT_SYMBOL_THRESHOLD;
    }

    static isCloseRated(rate = 1) {
        return rate < CLOSE_RATE_TREHSOLD;
    }

    static getSymbol(code = 'usd', locale = 'en') {
        const parts = new Intl.NumberFormat(locale, { style: 'currency', currency: code }).formatToParts(0);

        // NOTE: For some currencies the currency is on the first index and for some on the last
        return parts.find(part => part.type === 'currency').value;
    }

    static format(price, rate, locale, currency) {
        return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price * rate);
    }
}

export default CurrencyService;
