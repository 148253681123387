const ActionTypes = {
    // ========================================================================
    // DATA ACTIONS
    // ========================================================================

    // Redux persist load finished
    DATA_REHYDRATATION_COMPLETE: 'DATA_REHYDRATATION_COMPLETE',

    // Export data
    DATA_EXPORT_CANCELED: 'DATA_EXPORT_CANCELED',
    DATA_EXPORT_ERROR: 'DATA_EXPORT_ERROR',
    DATA_EXPORT_FETCHING: 'DATA_EXPORT_FETCHING',
    DATA_EXPORT_RECEIVED: 'DATA_EXPORT_RECEIVED',
    DATA_LIST_EXPORT_REQUESTED: 'DATA_LIST_EXPORT_REQUESTED',
    DATA_SELECTED_KW_EXPORT_REQUESTED: 'DATA_SELECTED_KW_EXPORT_REQUESTED',
    DATA_SELECTED_KW_ADD_TO_LIST_REQUESTED: 'DATA_SELECTED_KW_ADD_TO_LIST_REQUESTED',
    DATA_CURRENT_LIST_EXPORT_REQUESTED: 'DATA_CURRENT_LIST_EXPORT_REQUESTED',

    // Import data
    DATA_IMPORT_CURRENT_KEYWORDS_REQUESTED: 'DATA_IMPORT_CURRENT_KEYWORDS_REQUESTED',
    DATA_IMPORT_ERROR: 'DATA_IMPORT_ERROR',
    DATA_IMPORT_FETCHING: 'DATA_IMPORT_FETCHING',
    DATA_IMPORT_RECEIVED: 'DATA_IMPORT_RECEIVED',
    DATA_IMPORT_REQUESTED: 'DATA_IMPORT_REQUESTED',

    // History data
    DATA_HISTORY_ERROR: 'DATA_HISTORY_ERROR',
    DATA_HISTORY_FETCHING: 'DATA_HISTORY_FETCHING',
    DATA_HISTORY_RECEIVED: 'DATA_HISTORY_RECEIVED',
    DATA_HISTORY_REQUESTED: 'DATA_HISTORY_REQUESTED',
    DATA_HISTORY_SKIPPED: 'DATA_HISTORY_SKIPPED',
    DATA_HISTORY_DELETE_REQUESTED: 'DATA_HISTORY_DELETE_REQUESTED',
    DATA_HISTORY_DELETE_ERROR: 'DATA_HISTORY_DELETE_ERROR',
    DATA_HISTORY_DELETE_RECEIVED: 'DATA_HISTORY_DELETE_RECEIVED',

    // KD History data
    DATA_KD_HISTORY_ERROR: 'DATA_KD_HISTORY_ERROR',
    DATA_KD_HISTORY_FETCHING: 'DATA_KD_HISTORY_FETCHING',
    DATA_KD_HISTORY_RECEIVED: 'DATA_KD_HISTORY_RECEIVED',
    DATA_KD_HISTORY_REQUESTED: 'DATA_KD_HISTORY_REQUESTED',
    DATA_KD_HISTORY_SKIPPED: 'DATA_KD_HISTORY_SKIPPED',
    DATA_KD_HISTORY_DELETE_REQUESTED: 'DATA_KD_HISTORY_DELETE_REQUESTED',
    DATA_KD_HISTORY_DELETE_ERROR: 'DATA_KD_HISTORY_DELETE_ERROR',
    DATA_KD_HISTORY_DELETE_RECEIVED: 'DATA_KD_HISTORY_DELETE_RECEIVED',

    // fetch url data
    DATA_URL_DATA_ERROR: 'DATA_URL_DATA_ERROR',
    DATA_URL_DATA_FETCHING: 'DATA_URL_DATA_FETCHING',
    DATA_URL_DATA_RECEIVED: 'DATA_URL_DATA_RECEIVED',
    DATA_URL_DATA_REQUESTED: 'DATA_URL_DATA_REQUESTED',

    DATA_USER_SET_UNLEASH_SESSION: 'DATA_USER_SET_UNLEASH_SESSION',

    // Location data
    DATA_LOCATIONS_ERROR: 'DATA_LOCATIONS_ERROR',
    DATA_LOCATIONS_FETCHING: 'DATA_LOCATIONS_FETCHING',
    DATA_LOCATIONS_RECEIVED: 'DATA_LOCATIONS_RECEIVED',
    DATA_LOCATIONS_REQUESTED: 'DATA_LOCATIONS_REQUESTED',

    // Trends
    DATA_TRENDS_ERROR: 'DATA_TRENDS_ERROR',
    DATA_TRENDS_FETCHING: 'DATA_TRENDS_FETCHING',
    DATA_TRENDS_RECEIVED: 'DATA_TRENDS_RECEIVED',

    // Lists data
    DATA_LISTS_ADD_KEYWORDS_RECEIVED: 'DATA_LISTS_ADD_KEYWORDS_RECEIVED',
    DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE: 'DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE',
    DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE_REVERT: 'DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE_REVERT',
    DATA_LISTS_ADD_KEYWORDS_ERROR: 'DATA_LISTS_ADD_KEYWORDS_ERROR',
    DATA_LISTS_CREATE_ERROR: 'DATA_LISTS_CREATE_ERROR',
    DATA_LISTS_CURRENT_DELETE_KEYWORDS_CANCELLED: 'DATA_LISTS_CURRENT_DELETE_KEYWORDS_CANCELLED',
    DATA_LISTS_CURRENT_DELETE_KEYWORDS_ERROR: 'DATA_LISTS_CURRENT_DELETE_KEYWORDS_ERROR',
    DATA_LISTS_CURRENT_DELETE_KEYWORDS_REQUESTED: 'DATA_LISTS_CURRENT_DELETE_KEYWORDS_REQUESTED',
    DATA_LISTS_DELETE_KEYWORD_REQUESTED: 'DATA_LISTS_DELETE_KEYWORD_REQUESTED',
    DATA_LISTS_DELETE_CANCELLED: 'DATA_LISTS_DELETE_CANCELLED',
    DATA_LISTS_DELETE_ERROR: 'DATA_LISTS_DELETE_ERROR',
    DATA_LISTS_DELETE_FETCHING: 'DATA_LISTS_DELETE_FETCHING',
    DATA_LISTS_DELETE_RECEIVED: 'DATA_LISTS_DELETE_RECEIVED',
    DATA_LISTS_DELETE_REQUESTED: 'DATA_LISTS_DELETE_REQUESTED',
    DATA_LISTS_ERROR: 'DATA_LISTS_ERROR',
    DATA_LISTS_EXPORT_REQUESTED: 'DATA_LISTS_EXPORT_REQUESTED',
    DATA_LISTS_FETCHING: 'DATA_LISTS_FETCHING',
    DATA_LISTS_KEYWORDS_ERROR: 'DATA_LISTS_KEYWORDS_ERROR',
    DATA_LISTS_KEYWORDS_FETCHING: 'DATA_LISTS_KEYWORDS_FETCHING',
    DATA_LISTS_KEYWORDS_RECEIVED: 'DATA_LISTS_KEYWORDS_RECEIVED',
    DATA_LISTS_KEYWORDS_REQUESTED: 'DATA_LISTS_KEYWORDS_REQUESTED',
    DATA_LISTS_NEW_RECEIVED: 'DATA_LISTS_NEW_RECEIVED',
    DATA_LISTS_RECEIVED: 'DATA_LISTS_RECEIVED',
    DATA_LISTS_RENAME_ERROR: 'DATA_LISTS_RENAME_ERROR',
    DATA_LISTS_RENAME_FETCHING: 'DATA_LISTS_RENAME_FETCHING',
    DATA_LISTS_RENAME_RECEIVED: 'DATA_LISTS_RENAME_RECEIVED',
    DATA_LISTS_RENAME_REQUESTED: 'DATA_LISTS_RENAME_REQUESTED',
    DATA_LISTS_REQUESTED: 'DATA_LISTS_REQUESTED',
    DATA_LISTS_SKIPPED: 'DATA_LISTS_SKIPPED',
    DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC: 'DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC',
    DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT: 'DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT',
    DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC: 'DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC',
    DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT: 'DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT',

    // Related keywords data
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_EMPTY: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_EMPTY',
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_ERROR: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_ERROR',
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_FETCHING: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_FETCHING',
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_RECEIVED: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_RECEIVED',
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_REQUESTED: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_REQUESTED',
    DATA_KEYWORDS_BY_DOMAIN_RESULTS_SKIPPED: 'DATA_KEYWORDS_BY_DOMAIN_RESULTS_SKIPPED',

    // Competitors data
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_EMPTY: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_EMPTY',
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_ERROR: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_ERROR',
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_FETCHING: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_FETCHING',
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_RECEIVED: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_RECEIVED',
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_REQUESTED: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_REQUESTED',
    DATA_COMPETITOR_BY_DOMAINS_RESULTS_SKIPPED: 'DATA_COMPETITOR_BY_DOMAINS_RESULTS_SKIPPED',
    DATA_RESULTS_ERROR: 'DATA_RESULTS_ERROR',
    DATA_RESULTS_FETCHING: 'DATA_RESULTS_FETCHING',
    DATA_RESULTS_GOOGLE_AUTOCOMPLETE_REQUESTED: 'DATA_RESULTS_GOOGLE_AUTOCOMPLETE_REQUESTED',
    DATA_RESULTS_GOOGLE_EMPTY: 'DATA_RESULTS_GOOGLE_EMPTY',
    DATA_RESULTS_GOOGLE_ERROR: 'DATA_RESULTS_GOOGLE_ERROR',
    DATA_RESULTS_GOOGLE_FETCHING: 'DATA_RESULTS_GOOGLE_FETCHING',
    DATA_RESULTS_GOOGLE_FINISHED: 'DATA_RESULTS_GOOGLE_FINISHED',
    DATA_RESULTS_GOOGLE_QUESTIONS_REQUESTED: 'DATA_RESULTS_GOOGLE_QUESTIONS_REQUESTED',
    DATA_RESULTS_KEYWORD_RANK_RECEIVED: 'DATA_RESULTS_KEYWORD_RANK_RECEIVED',
    DATA_RESULTS_KEYWORD_RANK_EMPTY: 'DATA_RESULTS_KEYWORD_RANK_EMPTY',
    DATA_RESULTS_RECEIVED: 'DATA_RESULTS_RECEIVED',
    DATA_RESULTS_REQUESTED: 'DATA_RESULTS_REQUESTED',

    // Serp data
    DATA_SERP_RESULTS_ERROR: 'DATA_SERP_RESULTS_ERROR',
    DATA_SERP_RESULTS_FETCHING: 'DATA_SERP_RESULTS_FETCHING',
    DATA_SERP_RESULTS_MORE_ERROR: 'DATA_SERP_RESULTS_MORE_ERROR',
    DATA_SERP_RESULTS_MORE_FETCHING: 'DATA_SERP_RESULTS_MORE_FETCHING',
    DATA_SERP_RESULTS_MORE_NO_DATA_RECEIVED: 'DATA_SERP_RESULTS_MORE_NO_DATA_RECEIVED',
    DATA_SERP_RESULTS_MORE_RECEIVED: 'DATA_SERP_RESULTS_MORE_RECEIVED',
    DATA_SERP_RESULTS_MORE_REQUESTED: 'DATA_SERP_RESULTS_MORE_REQUESTED',
    DATA_SERP_RESULTS_NO_DATA_RECEIVED: 'DATA_SERP_RESULTS_NO_DATA_RECEIVED',
    DATA_SERP_RESULTS_RECEIVED: 'DATA_SERP_RESULTS_RECEIVED',
    DATA_SERP_RESULTS_REQUESTED: 'DATA_SERP_RESULTS_REQUESTED',
    DATA_SERP_RESULTS_UNCACHED_REQUESTED: 'DATA_SERP_RESULTS_UNCACHED_REQUESTED',

    // User data
    DATA_USER_CHECK_STATUS_ERROR: 'DATA_USER_CHECK_STATUS_ERROR',
    DATA_USER_CHECK_STATUS_SKIPPED: 'DATA_USER_CHECK_STATUS_SKIPPED',
    DATA_USER_DATA_ERROR: 'DATA_USER_DATA_ERROR',
    DATA_USER_DATA_FETCHING: 'DATA_USER_DATA_FETCHING',
    DATA_USER_DATA_RECEIVED: 'DATA_USER_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_ERROR: 'DATA_USER_LIMIT_DATA_ERROR',
    DATA_USER_LIMIT_DATA_FETCHING: 'DATA_USER_LIMIT_DATA_FETCHING',
    DATA_USER_LIMIT_DATA_RECEIVED: 'DATA_USER_LIMIT_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_REQUESTED: 'DATA_USER_LIMIT_DATA_REQUESTED',
    DATA_USER_LIMIT_DATA_SKIPPED: 'DATA_USER_LIMIT_DATA_SKIPPED',
    DATA_USER_LOGOUT_ERROR: 'DATA_USER_LOGOUT_ERROR',
    DATA_USER_LOGOUT_RECEIVED: 'DATA_USER_LOGOUT_RECEIVED',
    DATA_USER_LOGOUT_REQUESTED: 'DATA_USER_LOGOUT_REQUESTED',
    DATA_USER_AUTH_TOKENS_RECEIVED: 'DATA_USER_AUTH_TOKENS_RECEIVED',
    DATA_USER_DATA_SKIPPED: 'DATA_USER_DATA_SKIPPED',

    // Announcement message data
    DATA_ANNOUNCEMENTS_ERROR: 'DATA_ANNOUNCEMENTS_ERROR',
    DATA_ANNOUNCEMENTS_FETCHING: 'DATA_ANNOUNCEMENTS_FETCHING',
    DATA_ANNOUNCEMENTS_RECEIVED: 'DATA_ANNOUNCEMENTS_RECEIVED',

    // Currencies
    DATA_CURRENCY_RATES_ERROR: 'DATA_CURRENCY_RATES_ERROR',
    DATA_CURRENCY_RATES_FETCHING: 'DATA_CURRENCY_RATES_FETCHING',
    DATA_CURRENCY_RATES_RECEIVED: 'DATA_CURRENCY_RATES_RECEIVED',

    // Serpwatcher trackings
    DATA_SERPWATCHER_TRACKINGS_REQUESTED: 'DATA_SERPWATCHER_TRACKINGS_REQUESTED',
    DATA_SERPWATCHER_TRACKINGS_FETCHING: 'DATA_SERPWATCHER_TRACKINGS_FETCHING',
    DATA_SERPWATCHER_TRACKINGS_RECEIVED: 'DATA_SERPWATCHER_TRACKINGS_RECEIVED',
    DATA_SERPWATCHER_TRACKINGS_ERROR: 'DATA_SERPWATCHER_TRACKINGS_ERROR',

    // ========================================================================
    // PARAMS ACTIONS
    // ========================================================================
    PARAMS_SET: 'PARAMS_SET',

    // ========================================================================
    // UI ACTIONS
    // ========================================================================

    UI_ALL_CLOSE: 'UI_ALL_CLOSE',
    UI_DASHBOARD_CURRENT_KEYWORD_ID_SET: 'UI_DASHBOARD_CURRENT_KEYWORD_ID_SET',
    UI_DASHBOARD_KEYWORD_SOURCE_SET: 'UI_DASHBOARD_KEYWORD_SOURCE_SET',
    UI_DASHBOARD_LEFT_LOADER_PROGRESS_SET: 'UI_DASHBOARD_LEFT_LOADER_PROGRESS_SET',
    UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT: 'UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT',
    UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT_ALL_REQUESTED: 'UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT_ALL_REQUESTED',
    UI_DASHBOARD_SELECTED_KEYWORD_IDS_SET: 'UI_DASHBOARD_SELECTED_KEYWORD_IDS_SET',
    UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT: 'UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT',
    UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT_ALL: 'UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT_ALL',
    UI_DASHBOARD_SERP_EXPAND_TOGGLE: 'UI_DASHBOARD_SERP_EXPAND_TOGGLE',
    UI_DASHBOARD_SERP_ITEM_ACTIONS_DROPDOWN_VISIBLE_ID_SET: 'UI_DASHBOARD_SERP_ITEM_ACTIONS_DROPDOWN_VISIBLE_ID_SET',
    UI_DASHBOARD_SORTING_SETTINGS_RESET: 'UI_DASHBOARD_SORTING_SETTINGS_RESET',
    UI_DASHBOARD_SORTING_SETTINGS_SET: 'UI_DASHBOARD_SORTING_SETTINGS_SET',
    UI_DASHBOARD_SELECTED_SEARCH_VOLUME_TIMEFRAME_SET: 'UI_DASHBOARD_SELECTED_SEARCH_VOLUME_TIMEFRAME_SET',
    UI_DROPDOWNS_EXPORT_CLOSE: 'UI_DROPDOWNS_EXPORT_CLOSE',
    UI_DROPDOWNS_EXPORT_SHOW: 'UI_DROPDOWNS_EXPORT_SHOW',
    UI_DROPDOWNS_HELP_CLOSE: 'UI_DROPDOWNS_HELP_CLOSE',
    UI_DROPDOWNS_HELP_SHOW: 'UI_DROPDOWNS_HELP_SHOW',
    UI_DROPDOWNS_LINKS_CLOSE: 'UI_DROPDOWNS_LINKS_CLOSE',
    UI_DROPDOWNS_LINKS_SHOW: 'UI_DROPDOWNS_LINKS_SHOW',
    UI_DROPDOWNS_LIST_ACTIONS_CLOSE: 'UI_DROPDOWNS_LIST_ACTIONS_CLOSE',
    UI_DROPDOWNS_LIST_ACTIONS_SHOW: 'UI_DROPDOWNS_LIST_ACTIONS_SHOW',
    UI_DROPDOWNS_LIST_DETAIL_ACTIONS_CLOSE: 'UI_DROPDOWNS_LIST_DETAIL_ACTIONS_CLOSE',
    UI_DROPDOWNS_LIST_DETAIL_ACTIONS_SHOW: 'UI_DROPDOWNS_LIST_DETAIL_ACTIONS_SHOW',
    UI_FILTER_ACTIVE_SET: 'UI_FILTER_ACTIVE_SET',
    UI_FILTER_RESET: 'UI_FILTER_RESET',
    UI_FILTER_SETTINGS_SET: 'UI_FILTER_SETTINGS_SET',
    UI_FILTER_SHOW: 'UI_FILTER_SHOW',
    UI_FILTER_QUICK_SETTINGS_SEARCH: 'UI_FILTER_QUICK_SETTINGS_SEARCH',
    UI_FILTER_VISIBILITY_TOGGLE: 'UI_FILTER_VISIBILITY_TOGGLE',
    UI_MESSAGES_ACCESS_DENIED_CLOSE: 'UI_MESSAGES_ACCESS_DENIED_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_SHOW: 'UI_MESSAGES_ACCESS_DENIED_SHOW',
    UI_MESSAGES_ADD_TO_LIST_CLOSE: 'UI_MESSAGES_ADD_TO_LIST_CLOSE',
    UI_MESSAGES_ADD_TO_LIST_SHOW: 'UI_MESSAGES_ADD_TO_LIST_SHOW',
    UI_MESSAGES_ADD_TO_LIST_ADDING_KW_IDS_SET: 'UI_MESSAGES_ADD_TO_LIST_ADDING_KW_IDS_SET',
    UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: 'UI_MESSAGES_DELETE_CONFIRMATION_CLOSE',
    UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM: 'UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM',
    UI_MESSAGES_DELETE_CONFIRMATION_SHOW: 'UI_MESSAGES_DELETE_CONFIRMATION_SHOW',
    UI_MESSAGES_EXPORT_CLOSE: 'UI_MESSAGES_EXPORT_CLOSE',
    UI_MESSAGES_EXPORT_SHOW: 'UI_MESSAGES_EXPORT_SHOW',
    UI_MESSAGES_FAILURE_CLOSE: 'UI_MESSAGES_FAILURE_CLOSE',
    UI_MESSAGES_FAILURE_SHOW: 'UI_MESSAGES_FAILURE_SHOW',
    UI_MESSAGES_GAUGE_INFO_CLOSE: 'UI_MESSAGES_GAUGE_INFO_CLOSE',
    UI_MESSAGES_GAUGE_INFO_SHOW: 'UI_MESSAGES_GAUGE_INFO_SHOW',
    UI_MESSAGES_REFRESH_CONFIRM_SHOW: 'UI_MESSAGES_REFRESH_CONFIRM_SHOW',
    UI_MESSAGES_REFRESH_CONFIRM_CLOSE: 'UI_MESSAGES_REFRESH_CONFIRM_CLOSE',
    UI_MESSAGES_REFRESH_CONFIRM_PROCEED: 'UI_MESSAGES_REFRESH_CONFIRM_PROCEED',
    UI_MESSAGES_LOGGED_OUT_CLOSE: 'UI_MESSAGES_LOGGED_OUT_CLOSE',
    UI_MESSAGES_LOGGED_OUT_SHOW: 'UI_MESSAGES_LOGGED_OUT_SHOW',
    UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE: 'UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE',
    UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW: 'UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW',
    UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: 'UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE',
    UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: 'UI_MESSAGES_NEED_TO_SIGN_IN_SHOW',
    UI_MESSAGES_NO_CONNECTION_CLOSE: 'UI_MESSAGES_NO_CONNECTION_CLOSE',
    UI_MESSAGES_NO_CONNECTION_SHOW: 'UI_MESSAGES_NO_CONNECTION_SHOW',
    UI_MESSAGES_PRICING_CLOSE: 'UI_MESSAGES_PRICING_CLOSE',
    UI_MESSAGES_PRICING_SHOW: 'UI_MESSAGES_PRICING_SHOW',
    UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: 'UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE',
    UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_CLOSE: 'UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_CLOSE',
    UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_SHOW: 'UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_SHOW',
    UI_MESSAGES_URL_TYPE_INFO_CLOSE: 'UI_MESSAGES_URL_TYPE_INFO_CLOSE',
    UI_MESSAGES_URL_TYPE_INFO_SHOW: 'UI_MESSAGES_URL_TYPE_INFO_SHOW',
    UI_MESSAGES_SHORTCUTS_SHOW: 'UI_MESSAGES_SHORTCUTS_SHOW',
    UI_MESSAGES_SHORTCUTS_CLOSE: 'UI_MESSAGES_SHORTCUTS_CLOSE',
    UI_MESSAGES_CURRENCIES_SHOW: 'UI_MESSAGES_CURRENCIES_SHOW',
    UI_MESSAGES_CURRENCIES_CLOSE: 'UI_MESSAGES_CURRENCIES_CLOSE',
    UI_MESSAGES_TRACK_IN_SW_SHOW: 'UI_MESSAGES_TRACK_IN_SW_SHOW',
    UI_MESSAGES_TRACK_IN_SW_CLOSE: 'UI_MESSAGES_TRACK_IN_SW_CLOSE',
    UI_MISC_COLOR_SCHEME_SET: 'UI_MISC_COLOR_SCHEME_SET',
    UI_MISC_CURRENCY_SET: 'UI_MISC_CURRENCY_SET',
    UI_MISC_EXPORTING_SET: 'UI_MISC_EXPORTING_SET',
    UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_HIDE: 'UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_HIDE',
    UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_SHOW: 'UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_SHOW',
    UI_MISC_NAVIGATED_INTERNALLY_SET: 'UI_MISC_NAVIGATED_INTERNALLY_SET',
    UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: 'UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET',
    UI_MISC_NOTIFICATIONS_CLOSE_ALL: 'UI_MISC_NOTIFICATIONS_CLOSE_ALL',
    UI_MISC_NOTIFICATIONS_ERROR_SHOW: 'UI_MISC_NOTIFICATIONS_ERROR_SHOW',
    UI_MISC_NOTIFICATIONS_INFO_SHOW: 'UI_MISC_NOTIFICATIONS_INFO_SHOW',
    UI_MISC_ONLINE_STATUS_SET: 'UI_MISC_ONLINE_STATUS_SET',
    UI_OVERLAY_CLOSE: 'UI_OVERLAY_CLOSE',
    UI_OVERLAY_SHOW: 'UI_OVERLAY_SHOW',
    UI_PANELS_APP_CLOSE: 'UI_PANELS_APP_CLOSE',
    UI_PANELS_APP_SHOW: 'UI_PANELS_APP_SHOW',
    UI_PANELS_HISTORY_CLOSE: 'UI_PANELS_HISTORY_CLOSE',
    UI_PANELS_HISTORY_SHOW: 'UI_PANELS_HISTORY_SHOW',
    UI_PANELS_LIST_CLOSE: 'UI_PANELS_LIST_CLOSE',
    UI_PANELS_LIST_FILTER_RESET: 'UI_PANELS_LIST_FILTER_RESET',
    UI_PANELS_LIST_FILTER_SET: 'UI_PANELS_LIST_FILTER_SET',
    UI_PANELS_LIST_SHOW: 'UI_PANELS_LIST_SHOW',
    UI_COMPARING_BOX_URL_PROTOCOL_SET: 'UI_COMPARING_BOX_URL_PROTOCOL_SET',
    UI_COMPARING_BOX_URL_SET: 'UI_COMPARING_BOX_URL_SET',
    UI_RELATIVE_KD_ACTIVE_SET: 'UI_RELATIVE_KD_ACTIVE_SET',
    UI_RELATIVE_KD_DROPDOWN_TOGGLE: 'UI_RELATIVE_KD_DROPDOWN_SHOW',

    // UI DEFAULTS ACTIONS
    UI_DEFAULTS_COMPETITOR_LOCATION_SET: 'UI_DEFAULTS_COMPETITOR_LOCATION_SET',
    UI_DEFAULTS_COMPETITOR_QUERY_SET: 'UI_DEFAULTS_COMPETITOR_QUERY_SET',
    UI_DEFAULTS_COMPETITOR_ROW_EXPANDED_SET: 'UI_DEFAULTS_COMPETITOR_ROW_EXPANDED_SET',
    UI_DEFAULTS_GOOGLE_LANGUAGE_SET: 'UI_DEFAULTS_GOOGLE_LANGUAGE_SET',
    UI_DEFAULTS_GOOGLE_LOCATION_SET: 'UI_DEFAULTS_GOOGLE_LOCATION_SET',
    UI_DEFAULTS_GOOGLE_QUERY_SET: 'UI_DEFAULTS_GOOGLE_QUERY_SET',
    UI_DEFAULTS_SHARED_CURRENT_KEYWORD_GRAPH_SET: 'UI_DEFAULTS_SHARED_CURRENT_KEYWORD_GRAPH_SET',
    UI_DEFAULTS_SHARED_DATA_SOURCE_SET: 'UI_DEFAULTS_SHARED_DATA_SOURCE_SET',
    UI_DEFAULTS_SHARED_DATA_SUB_SOURCE_SET: 'UI_DEFAULTS_SHARED_DATA_SUB_SOURCE_SET',

    // ========================================================================
    // OTHER ACTIONS
    // ========================================================================

    ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ROUTER_NAVIGATION_BACK_REQUESTED: 'ROUTER_NAVIGATION_BACK_REQUESTED',
    ROUTER_NAVIGATION_REQUESTED: 'ROUTER_NAVIGATION_REQUESTED',
    ROUTER_AUTH_TOKENS_HIDE_REQUESTED: 'ROUTER_AUTH_TOKENS_HIDE_REQUESTED',

    ANALYTICS_TRACK: 'ANALYTICS_TRACK',
};

export default ActionTypes;
