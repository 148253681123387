// const PARSED_DATA = {
//     ctrData: [123, 213, 2323223, 23223, 2323],
//     keywordRank: 23,
//     keywordResults: 23000,
//     serpData: [{
//         citationFlow: 45,
//         domainAuthority: 13,
//         externalBacklinks: 2455,
//         facebookShares: 2,
//         id: '213dasdsa1e23dasdas',
//         rank: 54,
//         refDomains: 1833,
//         refIPs: 1423,
//         refSubnets: 1211,
//         trustFlow: 71,
//         uniqueIPCount: 11488,
//         url: 'http://www.hotels.com/'
//     }]
// };

import { isNil, filter, compose, map, addIndex, transduce, flip, append } from 'ramda';

import Defaults from 'mangools-commons/lib/constants/Defaults';

import { parseIntWithNVFallback } from 'sources/parsers/commons';
import { FEATURE_TYPES } from 'constants/SerpFeatureTypes';

const isMajesticPresent = item => !isNil(item.m?.majestic);
const isFacebookPresent = item => !isNil(item.m?.fb);
const isMozPresent = item => !isNil(item.m?.moz);
const isRankPresent = item => !isNil(item.m?.rank);

const decodeUrl = rawUrl => {
    try {
        const decodedUrl = decodeURIComponent(rawUrl);
        return decodedUrl;
    } catch (e) {
        // NOTE:
        // i.e. malformed URI error
        // we are fallbacking to the original URL, it should come from SERP so we believe in it :)
        return rawUrl;
    }
};

const parseSerpItemData = (item, index) => {
    const isFeaturedSnippet = index === 0 && item.type === FEATURE_TYPES.FEATURED_SNIPPET;

    return {
        citationFlow: isMajesticPresent(item)
            ? parseIntWithNVFallback(item.m.majestic.v.CitationFlow)
            : Defaults.NO_VALUE,
        domainAuthority: isMozPresent(item) ? parseIntWithNVFallback(item.m.moz.v.pda) : Defaults.NO_VALUE,
        externalBacklinks: isMajesticPresent(item)
            ? parseIntWithNVFallback(item.m.majestic.v.ExtBackLinks)
            : Defaults.NO_VALUE,
        facebookShares: isFacebookPresent(item) ? parseIntWithNVFallback(item.m.fb.v.l, 10) : Defaults.NO_VALUE,
        featuredSnippet: isFeaturedSnippet,
        type: item.type,
        ctr: item.ctr,
        id: item._id, // eslint-disable-line no-underscore-dangle
        pageAuthority: isMozPresent(item) ? parseIntWithNVFallback(item.m.moz.v.upa) : Defaults.NO_VALUE,
        rank: isRankPresent(item) ? parseIntWithNVFallback(item.m.rank.v.r, 10) : Defaults.NO_VALUE,
        refDomains: isMajesticPresent(item) ? parseIntWithNVFallback(item.m.majestic.v.RefDomains) : Defaults.NO_VALUE,
        refIPs: isMajesticPresent(item) ? parseIntWithNVFallback(item.m.majestic.v.RefIPs) : Defaults.NO_VALUE,
        refSubnets: isMajesticPresent(item) ? parseIntWithNVFallback(item.m.majestic.v.RefSubNets) : Defaults.NO_VALUE,
        trustFlow: isMajesticPresent(item) ? parseIntWithNVFallback(item.m.majestic.v.TrustFlow) : Defaults.NO_VALUE,
        url: decodeUrl(item.url),
    };
};

export const parseSerpData = rawData => {
    const transducer = compose(
        filter(serpResultItem => !isNil(FEATURE_TYPES[serpResultItem.type])),
        addIndex(map)((serpResultItem, index) => parseSerpItemData(serpResultItem, index)),
    );

    return transduce(transducer, flip(append), [], rawData);
};

export const parse = rawData => {
    const data = rawData[0]; // Should only contain one element

    return {
        kw: data.kw,
        rank: parseIntWithNVFallback(data.rank),
        rankUpdatedAt: parseIntWithNVFallback(data.rank_updated_at),
        serpData: parseSerpData(data.items),
        updatedAt: parseIntWithNVFallback(data.created_at), // NOTE: Should be called updated_at in API response...
    };
};
