import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    meta: {
        keyword: '',
        locationId: 0,
    },
};

const trendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TRENDS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_TRENDS_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload.data },
                error: { $set: initialState.error },
                meta: {
                    keyword: { $set: action.payload.meta.keyword },
                    locationId: { $set: action.payload.meta.locationId },
                },
            });
        }
        case ActionTypes.DATA_TRENDS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                meta: { $set: initialState.meta },
            });
        }
        default: {
            return state;
        }
    }
};

export default trendsReducer;
