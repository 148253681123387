import { arrayOf, number, shape, string } from 'prop-types';

export default shape({
    createdAt: number.isRequired,
    id: string.isRequired,
    keywordIds: arrayOf(string).isRequired,
    name: string.isRequired,
    updatedAt: number.isRequired,
    userId: string.isRequired,
});
