import React from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { connect } from 'react-redux';

import { requestedSelectedKwAddToListAction, requestedSerpwatcherTrackingsAction } from 'actions/dataActions';

import {
    closeAccessDeniedMessage,
    closeAddToListMessage,
    closeDeleteConfirmationMessage,
    closeFailureMessage,
    closeGaugeInfoMessage,
    closeRefreshConfirmMessage,
    proceedRefreshConfirmMessage,
    closeLoggedOutMessage,
    closeNeedToSelectKeywordMessage,
    closeNeedToSignInMessage,
    closeNoConnectionMessage,
    closePricingMessage,
    closeUpgradeForMoreResultsMessage,
    closeUrlTypeInfoMessage,
    closeShortcutsMessage,
    confirmDeleteConfirmationMessage,
    setCurrency,
    closeCurrenciesMessage,
    closeTrackInSerpwatcherMessage,
} from 'actions/uiActions';

import { gtmTrack } from 'actions/analyticsActions';

import AccessDeniedMessage from 'components/messages/AccessDeniedMessage';
import AddToListMessage from 'components/messages/AddToListMessage';
import DeleteConfirmationMessage from 'components/messages/DeleteConfirmationMessage';
import FailureMessage from 'components/messages/FailureMessage';
import GaugeInfoMessage from 'components/messages/GaugeInfoMessage';
import RefreshConfirmMessage from 'components/messages/RefreshConfirmMessage';
import LoggedOutMessage from 'components/messages/LoggedOutMessage';
import NeedToSelectKeywordMessage from 'components/messages/NeedToSelectKeywordMessage';
import NeedToSignInMessage from 'components/messages/NeedToSignInMessage';
import NoConnectionMessage from 'components/messages/NoConnectionMessage';
import PricingMessage from 'components/messages/PricingMessage';
import UpgradeForMoreResultsMessage from 'components/messages/UpgradeForMoreResultsMessage';
import UrlTypeInfoMessage from 'components/messages/UrlTypeInfoMessage';
import ShortcutsMessage from 'components/messages/ShortcutsMessage';
import CurrencyMessage from 'components/messages/CurrencyMessage';
import TrackInSerpwatcherMessage from 'components/messages/TrackInSerpwatcherMessage';

import {
    lastUsedOrCreatedListSelector,
    sortedListDataSelector,
    currenciesSelector,
    currenciesFetchingSelector,
    fetchingListsDataSelector,
    serpwatcherTrackingsDataSelector,
    fetchingSerpwatcherTrackingsSelector,
    filteredAndSortedSelectedKeywordIdsSelector,
    filteredAndSortedSelectedKeywordIdsWithRankUpdatedNotInLastTwentyFourHoursSelector,
} from 'selectors/dataSelectors';

import {
    pricingMessageVisibilitySelector,
    upgradeForMoreResultsVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    currenciesMessageVisibilitySelector,
    currencySelector,
    trackInSerpwatcherMessageVisibilitySelector,
    trackInSerpwatcherListIdSelector,
} from 'selectors/uiSelectors';

import { userPlanTypeSelector } from 'selectors/userSelectors';

import ListType from 'types/ListType';
import CurrencyType from 'types/CurrencyType';
import SerpwatcherTrackingsDataType from 'types/SerpwatcherTrackingsDataType';
import { analyticsActions, analyticsEvents } from 'constants/analytics';

function MessageContainer(props) {
    return (
        <div>
            <GaugeInfoMessage visible={props.gaugeInfoVisible} onClose={props.onCloseGaugeInfoMessage} />
            <RefreshConfirmMessage
                selectedKeywordCount={props.selectedKeywordCount}
                serpRequestRemaining={props.serpRequestRemaining}
                visible={props.refreshConfirmVisible}
                onClose={props.onCloseRefreshConfirmMessage}
                proceed={() => {
                    props.onProceedRefreshConfirmMessage(props.selectedKeywordIds);
                    props.onGtmTrack({
                        event: analyticsEvents.BULK_CHECK_PROCEED,
                        keywordCount: props.selectedKeywordCount,
                    });
                }}
            />
            <AccessDeniedMessage visible={props.accessDeniedVisible} onClose={props.onCloseAccessDeniedMessage} />
            <NeedToSelectKeywordMessage
                visible={props.needToSelectKeywordVisible}
                onClose={props.onCloseNeedToSelectKeywordMessage}
            />
            <DeleteConfirmationMessage
                onClose={props.onCloseDeleteConfirmationMessage}
                onConfirm={props.onConfirmDeleteConfirmationMessage}
                resourceName={props.deleteConfirmationResourceName}
                resourceType={props.deleteConfirmationResourceType}
                visible={props.deleteConfirmationVisible}
            />
            <NeedToSignInMessage visible={props.needToSignInVisible} onClose={props.onCloseNeedToSignInMessage} />
            <FailureMessage
                details={props.failureDetails}
                header={props.failureHeader}
                onClose={props.onCloseFailureMessage}
                visible={props.failureVisible}
            />
            <PricingMessage
                onClose={props.onClosePricingMessage}
                userPlanType={props.userPlanType}
                visible={props.pricingVisible}
            />
            <AddToListMessage
                defaultList={props.defaultList}
                addingKeywordCount={props.selectedKeywordCount}
                onClose={props.onCloseAddToListMessage}
                onListChosen={props.onListChosen}
                sortedListData={props.sortedListData}
                fetchingLists={props.fetchingLists}
                visible={props.addToListVisible}
            />
            <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            <UpgradeForMoreResultsMessage
                onClose={props.onCloseUpgradeForMoreResultsMessage}
                visible={props.upgradeForMoreResultsVisible}
            />
            <LoggedOutMessage onClose={props.onCloseLoggedOutMessage} visible={props.loggedOutVisible} />
            <UrlTypeInfoMessage onClose={props.onCloseUrlTypeInfoMessage} visible={props.urlTypeInfoVisible} />
            <ShortcutsMessage onClose={props.onCloseShortcutsMessage} visible={props.shortcutsMessageVisible} />
            <CurrencyMessage
                visible={props.currenciesVisible}
                onClose={props.onCloseCurrenciesMessage}
                currencies={props.currencies}
                onSetCurrency={props.onSetCurrency}
                currency={props.currency}
                currenciesFetching={props.currenciesFetching}
            />
            <TrackInSerpwatcherMessage
                visible={props.trackInSerpwatcherVisible}
                onClose={props.onCloseTrackInSerpwatcherMessage}
                currentListId={props.currentListId}
                fetchingSerpwatcherTrackings={props.fetchingSerpwatcherTrackings}
                serpwatcherTrackings={props.serpwatcherTrackings}
                requestSerpwatcherTrackingsAction={props.requestSerpwatcherTrackingsAction}
                listId={props.trackInSerpwatcherListId}
            />
        </div>
    );
}

MessageContainer.propTypes = {
    defaultList: ListType,
    accessDeniedVisible: bool.isRequired,
    addToListVisible: bool.isRequired,
    deleteConfirmationResourceName: string.isRequired,
    deleteConfirmationResourceType: string.isRequired,
    deleteConfirmationVisible: bool.isRequired,
    failureDetails: string,
    failureHeader: string,
    failureVisible: bool.isRequired,
    gaugeInfoVisible: bool.isRequired,
    refreshConfirmVisible: bool.isRequired,
    loggedOutVisible: bool.isRequired,
    needToSelectKeywordVisible: bool.isRequired,
    needToSignInVisible: bool.isRequired,
    noConnectionVisible: bool.isRequired,
    onCloseAccessDeniedMessage: func.isRequired,
    onCloseAddToListMessage: func.isRequired,
    onCloseDeleteConfirmationMessage: func.isRequired,
    onCloseFailureMessage: func.isRequired,
    onCloseGaugeInfoMessage: func.isRequired,
    onCloseRefreshConfirmMessage: func.isRequired,
    onProceedRefreshConfirmMessage: func.isRequired,
    onCloseLoggedOutMessage: func.isRequired,
    onCloseNeedToSelectKeywordMessage: func.isRequired,
    onCloseNeedToSignInMessage: func.isRequired,
    onCloseNoConnectionMessage: func.isRequired,
    onClosePricingMessage: func.isRequired,
    onCloseUpgradeForMoreResultsMessage: func.isRequired,
    onCloseUrlTypeInfoMessage: func.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    onConfirmDeleteConfirmationMessage: func.isRequired,
    onListChosen: func.isRequired,
    pricingVisible: bool.isRequired,
    selectedKeywordIds: arrayOf(string).isRequired,
    selectedKeywordCount: number.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    sortedListData: arrayOf(ListType).isRequired,
    upgradeForMoreResultsVisible: bool.isRequired,
    urlTypeInfoVisible: bool.isRequired,
    userPlanType: string.isRequired,
    onCloseCurrenciesMessage: func.isRequired,
    currencies: arrayOf(CurrencyType),
    onSetCurrency: func.isRequired,
    currenciesVisible: bool.isRequired,
    currency: CurrencyType,
    currenciesFetching: bool.isRequired,
    fetchingLists: bool.isRequired,
    trackInSerpwatcherVisible: bool.isRequired,
    onCloseTrackInSerpwatcherMessage: func.isRequired,
    currentListId: string,
    fetchingSerpwatcherTrackings: bool.isRequired,
    serpwatcherTrackings: SerpwatcherTrackingsDataType,
    serpRequestRemaining: number.isRequired,
    requestSerpwatcherTrackingsAction: func.isRequired,
    trackInSerpwatcherListId: string,
    onGtmTrack: func.isRequired,
};

MessageContainer.defaultProps = {
    defaultList: null,
    failureDetails: null,
};

const mapStateToProps = (state, _ownProps) => ({
    accessDeniedVisible: state.ui.messages.accessDenied.visibility,
    addToListVisible: state.ui.messages.addToList.visibility,
    defaultList: lastUsedOrCreatedListSelector(state),
    deleteConfirmationResourceName: state.ui.messages.deleteConfirmation.resourceName,
    deleteConfirmationResourceType: state.ui.messages.deleteConfirmation.resourceType,
    deleteConfirmationVisible: state.ui.messages.deleteConfirmation.visibility,
    failureDetails: state.ui.messages.failure.details,
    failureHeader: state.ui.messages.failure.header,
    failureVisible: state.ui.messages.failure.visibility,
    gaugeInfoVisible: state.ui.messages.gaugeInfo.visibility,
    refreshConfirmVisible: state.ui.messages.refreshConfirm.visibility,
    loggedOutVisible: state.ui.messages.loggedOut.visibility,
    needToSelectKeywordVisible: state.ui.messages.needToSelectKeyword.visibility,
    needToSignInVisible: state.ui.messages.needToSignIn.visibility,
    noConnectionVisible: state.ui.messages.noConnection.visibility,
    pricingVisible: pricingMessageVisibilitySelector(state),
    selectedKeywordIds: filteredAndSortedSelectedKeywordIdsWithRankUpdatedNotInLastTwentyFourHoursSelector(state),
    selectedKeywordCount: filteredAndSortedSelectedKeywordIdsSelector(state).length,
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    sortedListData: sortedListDataSelector(state),
    upgradeForMoreResultsVisible: upgradeForMoreResultsVisibilitySelector(state),
    urlTypeInfoVisible: state.ui.messages.urlTypeInfo.visibility,
    userPlanType: userPlanTypeSelector(state),
    currencies: currenciesSelector(state),
    currenciesVisible: currenciesMessageVisibilitySelector(state),
    currency: currencySelector(state),
    currenciesFetching: currenciesFetchingSelector(state),
    fetchingLists: fetchingListsDataSelector(state),
    trackInSerpwatcherVisible: trackInSerpwatcherMessageVisibilitySelector(state),
    serpwatcherTrackings: serpwatcherTrackingsDataSelector(state),
    serpRequestRemaining: state.user.limits.serpRequestRemaining,
    fetchingSerpwatcherTrackings: fetchingSerpwatcherTrackingsSelector(state),
    trackInSerpwatcherListId: trackInSerpwatcherListIdSelector(state),
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onConfirmDeleteConfirmationMessage() {
        dispatch(confirmDeleteConfirmationMessage());
    },
    onCloseDeleteConfirmationMessage() {
        dispatch(closeDeleteConfirmationMessage());
    },
    onCloseAccessDeniedMessage() {
        dispatch(closeAccessDeniedMessage());
    },
    onCloseAddToListMessage() {
        dispatch(closeAddToListMessage());
    },
    onCloseFailureMessage() {
        dispatch(closeFailureMessage());
    },
    onCloseGaugeInfoMessage() {
        dispatch(closeGaugeInfoMessage());
    },
    onCloseRefreshConfirmMessage() {
        dispatch(closeRefreshConfirmMessage());
    },
    onProceedRefreshConfirmMessage(ids) {
        dispatch(proceedRefreshConfirmMessage(ids));
    },
    onCloseLoggedOutMessage() {
        dispatch(closeLoggedOutMessage());
    },
    onCloseNoConnectionMessage() {
        dispatch(closeNoConnectionMessage());
    },
    onCloseNeedToSelectKeywordMessage() {
        dispatch(closeNeedToSelectKeywordMessage());
    },
    onCloseNeedToSignInMessage() {
        dispatch(closeNeedToSignInMessage());
    },
    onClosePricingMessage() {
        dispatch(closePricingMessage());
    },
    onCloseUpgradeForMoreResultsMessage() {
        dispatch(closeUpgradeForMoreResultsMessage());
    },
    onCloseUrlTypeInfoMessage() {
        dispatch(closeUrlTypeInfoMessage());
    },
    onListChosen(isNew, nameOrId) {
        dispatch(requestedSelectedKwAddToListAction(isNew, nameOrId));
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onSetCurrency(currency) {
        dispatch(setCurrency(currency));
        dispatch(
            gtmTrack({
                action: analyticsActions.CURRENCY_CHANGE,
                event: analyticsEvents.CURRENCY_CHANGE,
                currency: currency.code,
            }),
        );
    },
    onCloseCurrenciesMessage() {
        dispatch(closeCurrenciesMessage());
    },
    onCloseTrackInSerpwatcherMessage() {
        dispatch(closeTrackInSerpwatcherMessage());
    },
    requestSerpwatcherTrackingsAction() {
        dispatch(requestedSerpwatcherTrackingsAction());
    },
    onGtmTrack(payload) {
        dispatch(gtmTrack(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
