import config from 'appConfig';
import queryString from 'query-string';
import { parseIndex, parseDetail, parseCreate } from 'sources/parsers/ListDataParser';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';

// Constants
const ENDPOINT = '/lists';

/**
 * Data source class handling communication with API for keyword lists data.
 */
class ListSource {
    static getData(accessToken) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseIndex(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getDetail(accessToken, listId) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseDetail(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static create(accessToken, keywordIds, listName) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify({
                name: listName,
                keyword_ids: keywordIds,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseCreate(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete(accessToken, listId) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}`;

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static addKeywords({ accessToken, keywordIds, listId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}/keywords`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify({
                keyword_ids: keywordIds,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static deleteKeywords({ accessToken, keywordIds, listId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}/keywords`;

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify({
                keyword_ids: keywordIds,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static rename(accessToken, newName, listId) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}`;

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify({
                name: newName,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static export(accessToken, listId) {
        // Query params
        const query = {};

        // Generate request URL
        const requestQuery = queryString.stringify(query);
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}.csv?${requestQuery}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'text/csv',
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.text().then(data => ({
                        error: false,
                        payload: data,
                    }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default ListSource;
