import update, { extend } from 'immutability-helper';
import { concat, length, uniq, omit, filter, includes, isNil } from 'ramda';

import ActionTypes from 'constants/ActionTypes';

extend('$clearPatchedAction', (actionType, patches) => omit([actionType], patches));

const initialState = {
    count: 0,
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    patches: {},
};

const listsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_LISTS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_LISTS_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload,
                },
                count: {
                    $set: length(action.payload),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
            });
        }
        case ActionTypes.DATA_LISTS_NEW_RECEIVED: {
            return update(state, {
                data: {
                    $apply: lists => concat(lists, [action.payload]),
                },
                count: {
                    $apply: count => count + 1,
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_ADD_KEYWORDS_RECEIVED: {
            return update(state, {
                fetching: {
                    $set: false,
                },
                patches: {
                    $apply: patches =>
                        omit(
                            [
                                ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT,
                                ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT,
                            ],
                            patches,
                        ),
                },
            });
        }
        case ActionTypes.DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE: {
            return update(state, {
                data: {
                    $apply: lists =>
                        lists.map(list => {
                            if (list.id === action.payload.listId) {
                                return update(list, {
                                    keywordIds: {
                                        $apply: keywordIds => uniq(concat(keywordIds, action.payload.keywordIds)),
                                    },
                                    updatedAt: { $set: new Date().setSeconds(0, 0) },
                                });
                            } else {
                                return list;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE_REVERT: {
            return update(state, {
                data: {
                    $apply: lists =>
                        lists.map(list => {
                            if (list.id === action.payload.listId) {
                                return update(list, {
                                    keywordIds: { $set: action.payload.keywordIds },
                                    updatedAt: { $set: action.payload.updatedAt },
                                });
                            } else {
                                return list;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_LISTS_ERROR: {
            return update(state, {
                count: {
                    $set: initialState.count,
                },
                data: {
                    $set: initialState.data,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_CREATE_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_ADD_KEYWORDS_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC: {
            return update(state, {
                data: {
                    $apply: lists =>
                        lists.map(list => {
                            if (list.id === action.payload.listId) {
                                return update(list, {
                                    keywordIds: {
                                        $apply: keywordIds =>
                                            filter(
                                                keywordId => !includes(keywordId, action.payload.keywordIds),
                                                keywordIds,
                                            ),
                                    },
                                });
                            } else {
                                return list;
                            }
                        }),
                },
                patches: {
                    $merge: {
                        [ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT]: state.data,
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC: {
            return update(state, {
                data: {
                    $apply: lists =>
                        lists.map(list => {
                            if (list.id === action.payload.listId) {
                                return update(list, {
                                    keywordIds: {
                                        $apply: keywordIds =>
                                            filter(keywordId => keywordId !== action.payload.keywordId, keywordIds),
                                    },
                                });
                            } else {
                                return list;
                            }
                        }),
                },
                patches: {
                    $merge: {
                        [ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT]: state.data,
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT: {
            if (!isNil(state.patches[action.type])) {
                return update(state, {
                    data: { $merge: state.patches[action.type] },
                    patches: { $clearPatchedAction: action.type },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT: {
            if (!isNil(state.patches[action.type])) {
                return update(state, {
                    data: { $merge: state.patches[action.type] },
                    patches: { $clearPatchedAction: action.type },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_LISTS_DELETE_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_RECEIVED: {
            return update(state, {
                count: {
                    $apply: count => (count > 0 ? count - 1 : 0),
                },
                data: {
                    $apply: lists => lists.filter(list => list.id !== action.payload),
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_ERROR: {
            return update(state, {
                fetching: {
                    $set: false,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_LISTS_RENAME_RECEIVED: {
            return update(state, {
                data: {
                    $apply: lists =>
                        lists.map(list => {
                            if (list.id === action.payload.id) {
                                return update(list, {
                                    name: {
                                        $set: action.payload.name,
                                    },
                                });
                            } else {
                                return list;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_LISTS_RENAME_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_ERROR: {
            return update(state, {
                fetching: {
                    $set: false,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default listsReducer;
