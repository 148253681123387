export const AUTOCOMPLETE = 'AUTOCOMPLETE';
export const IMPORT = 'IMPORT';
export const LIST = 'LIST';
export const QUESTIONS = 'QUESTIONS';
export const RELATED = 'RELATED';
export const COMPETITOR = 'COMPETITOR';

export default {
    AUTOCOMPLETE,
    COMPETITOR,
    IMPORT,
    LIST,
    QUESTIONS,
    RELATED,
};

export const GOOGLE_TYPES = [AUTOCOMPLETE, QUESTIONS, RELATED];

export const AUTOCOMPLETE_TYPES = [AUTOCOMPLETE, QUESTIONS];
