import { number, oneOfType, shape, string } from 'prop-types';
import NoValueType from 'types/NoValueType';
import LanguageType from 'types/LanguageType';

export default shape({
    id: string,
    language: LanguageType.isRequired,
    location: shape({ id: number.isRequired, label: string.isRequired }).isRequired,
    query: string.isRequired,
    rank: oneOfType([number, NoValueType]).isRequired,
    rankUpdatedAt: oneOfType([number, NoValueType]).isRequired,
    type: number.isRequired,
});
