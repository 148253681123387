import CurrencyService from 'services/CurrencyService';
import { parseFloatWithNVFallback, parseStringWithNAFallback } from 'sources/parsers/commons';

const parseSymbol = (code, locale = 'en') => CurrencyService.getSymbol(code, locale);

const parseCurrencies = currencies =>
    Object.values(currencies).map(currency => ({
        name: parseStringWithNAFallback(currency.name),
        countryCode: parseStringWithNAFallback(currency.countryCode),
        code: currency.code,
        symbol: parseSymbol(currency.code, currency.locale),
        locale: parseStringWithNAFallback(currency.locale),
        rate: parseFloatWithNVFallback(currency.rate),
    }));

export const parse = rawData => ({
    timestamp: rawData.timestamp,
    currencies: parseCurrencies(rawData.currencies),
});
