import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for persistent UI changes
 */

export const setParams = payload => ({
    type: ActionTypes.PARAMS_SET,
    error: false,
    payload: {
        query: payload.query,
        languageId: payload.languageId,
        locationId: payload.locationId,
    },
});
