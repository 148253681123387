import fecha from 'fecha';
import isNil from 'ramda/src/isNil';
import Defaults from 'mangools-commons/lib/constants/Defaults';

import { JUST_NOW } from 'constants/Other';

const BASIC_FORMAT = 'Do MMM YY';
const BASIC_NO_YEAR_FORMAT = 'Do MMM';
const LONG_FORMAT = 'Do MMM, YYYY';
const MONTH_AND_YEAR_FORMAT = 'MMMM YYYY';
const SHORT_MONTH_YEAR = "MMM 'YY";
const VERY_LONG_FORMAT = 'dddd Do MMM, YYYY';

class DateFormatterService {
    static formatBasic(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), BASIC_FORMAT);
        }
    }

    static formatShortMonthYear(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), SHORT_MONTH_YEAR);
        }
    }

    static formatBasicNoYear(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), BASIC_NO_YEAR_FORMAT);
        }
    }

    static formatMonthAndYear(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), MONTH_AND_YEAR_FORMAT);
        }
    }

    static formatLong(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), LONG_FORMAT);
        }
    }

    static formatVeryLong(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return fecha.format(new Date(value), VERY_LONG_FORMAT);
        }
    }

    /* eslint-disable no-mixed-operators */
    /* eslint-disable prefer-template */
    static relativeAgoOrBasicYear(timestamp) {
        const date = new Date(timestamp);
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        const hourDiff = Math.floor(diff / 3600);
        const dayDiff = Math.floor(diff / 86400);

        if (Number.isNaN(dayDiff)) {
            return Defaults.NOT_AVAILABLE;
        } else {
            return (
                (hourDiff < 1 && JUST_NOW) ||
                (hourDiff < 24 && `${hourDiff} ${hourDiff === 1 ? 'hour' : 'hours'} ago`) ||
                (dayDiff < 31 && `${dayDiff} ${dayDiff === 1 ? 'day' : 'days'} ago`) ||
                DateFormatterService.formatBasic(timestamp)
            );
        }
    }
    /* eslint-enable no-mixed-operators */
    /* eslint-enable prefer-template */
}

export default DateFormatterService;
