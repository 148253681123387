import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

import Defaults from 'mangools-commons/lib/constants/Defaults';

const DEFAULT_DATA = {
    domain: '',
    id: null,
    url: '',
    linkProfileStrength: Defaults.NO_VALUE,
};

const initialState = {
    data: DEFAULT_DATA,
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const urlDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_URL_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_URL_DATA_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_URL_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default urlDataReducer;
